import Immutable from 'immutable'
import * as ActionType from './actions'

export const initialState = Immutable.fromJS({
  server: [],
  push: [],
  //TODO::finalevil::需檢查 ios 與 android 有什麼不同
  // enabled: Platform.OS === 'ios' ? null : true,
  enabled: null,
  registered: false,
  seen: {},
  notifyAlert: false,
})

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case '__NEXT_REDUX_WRAPPER_HYDRATE__':
      return Immutable.fromJS(action.payload.notifications)

    case ActionType.CHECK_NOTIFY_ALERT_SUCCESS:
      return state.setIn(['notifyAlert'], !!action.response.newMsg)

    case ActionType.CLEAR_NOTIFY_ALERT:
      return state.setIn(['notifyAlert'], false)

    case ActionType.UPDATE_PUSH_LOG_SUCCESS:
      return state

    default:
      return state
  }
}
