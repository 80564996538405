import { EventNames, FollowActionType } from './common'
import { getEventTracker } from '@libs/eventTracking'
import { getCurrentPagePropertyFor } from '@libs/eventTracking/eventPropertyUtils'

/**
 * [Link to spec](https://www.dropbox.com/scl/fi/2h3wqwmg0h11i58t295dd/%E3%80%90%E6%95%B8%E6%93%9A%E3%80%91%E5%80%8B%E4%BA%BA%E9%A0%81-_-%E6%94%B6%E8%97%8F%E9%A0%81.paper?dl=0)
 */
export function trackUserProfileInfoFollowEvent(mid: string, actionType: FollowActionType) {
  getEventTracker().track({
    name: EventNames.USER_PROFILE_INFO_FOLLOW,
    properties: {
      currentPage: getCurrentPagePropertyFor('user-profile'),
      actionType,
      actionTarget: mid,
    },
  })
}
