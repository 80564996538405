import Immutable from 'immutable'
import * as ActionType from './actions'

export const initialState = Immutable.fromJS({
  isLoading: true,
  errorMessage: '',
  gifts: {},
  coupons: {},
})

export default function popboxReducer(state = initialState, action) {
  switch (action.type) {
    case '__NEXT_REDUX_WRAPPER_HYDRATE__':
      return Immutable.fromJS(action.payload.popbox)

    case ActionType.GET_GIFT:
      return state.setIn(['isLoading'], true)

    case ActionType.GET_GIFT_SUCCESS: {
      if (action.response.status === 'success') {
        let result = Array.isArray(action.response.data)
          ? action.response.data
          : [action.response.data]
        const gifts = result
          .filter((e) => e.type == 'popbox')
          .reduce((obj, p) => ((obj[p.giftID] = p), obj), {})
        const coupons = result
          .filter((e) => e.type == 'popcoupon')
          .reduce((obj, p) => ((obj[p.giftID] = p), obj), {})
        return state
          .setIn(['isLoading'], false)
          .mergeIn(['gifts'], Immutable.fromJS(gifts))
          .mergeIn(['coupons'], Immutable.fromJS(coupons))
      } else {
        return state.setIn(['isLoading'], false)
      }
    }

    case ActionType.GET_GIFT_FAILED:
      return state
        .setIn(['isLoading'], false)
        .setIn(['errorMessage'], Immutable.fromJS(action.response))

    default:
      return state
  }
}
