import { useEffect } from 'react'
import { initEventTracker, getEventTracker } from '@libs/eventTracking'
import { getCurrentUser } from '@app-core/currentUser/selector'
import { UserProperty, LoginStatus } from '@app-core/eventTracking/properties/UserProperty'
import { mapSignUpTypeFromUid } from '@app-core/eventTracking/UserPropertyMapper'
import { useSelector } from 'react-redux'
import { useRNApp } from './RNApp'
import { theme } from 'twin.macro'
import { Platform } from './eventTracking/MixpanelTracker'

const appWithEventTracker = (App) => {
  const EnhancedApp = (props) => {
    const immutableCurrentUser = useSelector(getCurrentUser)
    const { isApp } = useRNApp()

    useEffect(() => {
      const userType = immutableCurrentUser.getIn(['userInfo', 'type'])

      if (userType === 'unknown') return
      const platform = window.matchMedia(`(min-width: ${theme`screens.md`})`).matches
        ? Platform.web
        : Platform.mweb

      const userInfo = immutableCurrentUser.get('userInfo').toJS()
      const mid = userInfo?.mid
      const uid = userInfo?.uid
      const registeredTimeInMillis = userInfo?.t
      const signUpPlatform = userInfo?.signUpPlatform
      const signUpAt = registeredTimeInMillis
        ? new Date(registeredTimeInMillis).toISOString()
        : undefined

      const userProperty: UserProperty = {
        loginStatus: userType === 'guest' ? LoginStatus.GUEST : LoginStatus.LOGGED_IN,
        userId: userType === 'guest' ? `guest-${mid}` : `user-${mid}`,
        signUpAt,
        signUpType: mapSignUpTypeFromUid(uid),
        signUpPlatform,
      }
      getEventTracker().setTrackUser(userProperty)

      initEventTracker(isApp, platform)
    }, [immutableCurrentUser])

    return <App {...props} />
  }

  EnhancedApp.getInitialProps = App.getInitialProps

  return EnhancedApp
}

export default appWithEventTracker
