import constantCreator from '../libs/constantCreator'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import { v1PostWithToken } from '../api/v1'
import { getUserToken } from '../currentUser/selector'

const createConst = constantCreator('notifications')

export const CHECK_NOTIFY_ALERT = createConst('CHECK_NOTIFY_ALERT')
export const CHECK_NOTIFY_ALERT_SUCCESS = createConst('CHECK_NOTIFY_ALERT_SUCCESS')
export const CHECK_NOTIFY_ALERT_FAILED = createConst('CHECK_NOTIFY_ALERT_FAILED')
export function checkNotifyAlert() {
  return async (dispatch, getState) => {
    dispatch({ type: CHECK_NOTIFY_ALERT })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken('/notify/check', {}, userToken)
      dispatch({ type: CHECK_NOTIFY_ALERT_SUCCESS, response })
    } catch (error) {
      dispatch({ type: CHECK_NOTIFY_ALERT_FAILED, error })
    }
  }
}

export const CLEAR_NOTIFY_ALERT = createConst('CLEAR_NOTIFY_ALERT')
export const CLEAR_NOTIFY_ALERT_SUCCESS = createConst('CLEAR_NOTIFY_ALERT_SUCCESS')
export const CLEAR_NOTIFY_ALERT_FAILED = createConst('CLEAR_NOTIFY_ALERT_FAILED')
export function clearNotifyAlert() {
  return async (dispatch, getState) => {
    dispatch({ type: CLEAR_NOTIFY_ALERT })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken('/notify/clear', {}, userToken)
      dispatch({ type: CLEAR_NOTIFY_ALERT_SUCCESS, response })
    } catch (error) {
      dispatch({ type: CLEAR_NOTIFY_ALERT_FAILED, error })
    }
  }
}

export const UPDATE_PUSH_LOG = createConst('UPDATE_PUSH_LOG')
export const UPDATE_PUSH_LOG_SUCCESS = createConst('UPDATE_PUSH_LOG_SUCCESS')
export const UPDATE_PUSH_LOG_FAILED = createConst('UPDATE_PUSH_LOG_FAILED')
export function updatePushLog({ appOnesignalPushId, isReceived, isOpened }) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_PUSH_LOG })

      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken(
        '/notify/log',
        omitBy({ appOnesignalPushId, isReceived, isOpened }, isNil),
        userToken,
      )
      dispatch({ type: UPDATE_PUSH_LOG_SUCCESS, response })
    } catch (error) {
      dispatch({ type: UPDATE_PUSH_LOG_FAILED, error })
    }
  }
}

export const SET_ONESIGNAL_PUSH_ID = createConst('SET_ONESIGNAL_PUSH_ID')
export const SET_ONESIGNAL_PUSH_ID_SUCCESS = createConst('SET_ONESIGNAL_PUSH_ID_SUCCESS')
export const SET_ONESIGNAL_PUSH_ID_FAILED = createConst('SET_ONESIGNAL_PUSH_ID_FAILED')
export function setOnesignalPushID({ onesignalID }) {
  if (!onesignalID) return () => {} // empty thunk action

  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_ONESIGNAL_PUSH_ID })

      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken(
        '/user/onesignalID/set',
        omitBy({ onesignalID }, isNil),
        userToken,
      )
      dispatch({ type: SET_ONESIGNAL_PUSH_ID_SUCCESS, response })
    } catch (error) {
      dispatch({ type: SET_ONESIGNAL_PUSH_ID_FAILED, error })
    }
  }
}
