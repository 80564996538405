import constantCreator from '../libs/constantCreator'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import { fetchPostList } from '../api/creator-v1'
import { v1GetWithToken, v1PostWithToken } from '../api/v1'
import { getUserToken } from '../currentUser/selector'

const createConst = constantCreator('creator')

export const GET_DASHBOARD_INFO = createConst('GET_DASHBOARD_INFO')
export const GET_DASHBOARD_INFO_SUCCESS = createConst('GET_DASHBOARD_INFO_SUCCESS')
export const GET_DASHBOARD_INFO_FAILED = createConst('GET_DASHBOARD_INFO_FAILED')
export function getDashboardInfo({ mid, startTimestamp, endTimestamp }) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_DASHBOARD_INFO })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1GetWithToken(
        '/creator/dashbord/info',
        omitBy({ mid, startTimestamp, endTimestamp }, isNil),
        userToken,
      )
      dispatch({ type: GET_DASHBOARD_INFO_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GET_DASHBOARD_INFO_FAILED, error })
    }
  }
}

export const GET_PAGEVIEW_DAILY = createConst('GET_PAGEVIEW_DAILY')
export const GET_PAGEVIEW_DAILY_SUCCESS = createConst('GET_PAGEVIEW_DAILY_SUCCESS')
export const GET_PAGEVIEW_DAILY_FAILED = createConst('GET_PAGEVIEW_DAILY_FAILED')
export function getPageViewDaily({ mid, startTimestamp, endTimestamp }) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_PAGEVIEW_DAILY })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1GetWithToken(
        '/creator/pageview/daily',
        { mid, startTimestamp, endTimestamp },
        userToken,
      )
      dispatch({ type: GET_PAGEVIEW_DAILY_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GET_PAGEVIEW_DAILY_FAILED, error })
    }
  }
}

export const GET_POST_LIST = createConst('GET_POST_LIST')
export const GET_POST_LIST_SUCCESS = createConst('GET_POST_LIST_SUCCESS')
export const GET_POST_LIST_FAILED = createConst('GET_POST_LIST_FAILED')
export const getPostList =
  ({ listType, limit, offset, startTimestamp, endTimestamp, orderby, clas, text, mid }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: GET_POST_LIST, listType })

      const userToken = getUserToken(getState())
      const { data: response } = await fetchPostList(
        omitBy(
          {
            limit,
            offset,
            startTimestamp,
            endTimestamp,
            orderby,
            clas,
            text,
            mid,
          },
          isNil,
        ),
        userToken,
      )
      dispatch({ type: GET_POST_LIST_SUCCESS, listType, response })
      return response
    } catch (error) {
      dispatch({ type: GET_POST_LIST_FAILED, listType, error })
    }
  }

export const GET_PAGEVIEW_SUM = createConst('GET_PAGEVIEW_SUM')
export const GET_PAGEVIEW_SUM_SUCCESS = createConst('GET_PAGEVIEW_SUM_SUCCESS')
export const GET_PAGEVIEW_SUM_FAILED = createConst('GET_PAGEVIEW_SUM_FAILED')
export function getPageViewSum({ mid, startTimestamp, endTimestamp }) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_PAGEVIEW_SUM })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1GetWithToken(
        '/creator/pageview/sum',
        omitBy({ mid, startTimestamp, endTimestamp }, isNil),
        userToken,
      )
      dispatch({ type: GET_PAGEVIEW_SUM_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GET_PAGEVIEW_SUM_FAILED, error })
    }
  }
}

export const GET_CREATOR_INFO = createConst('GET_CREATOR_INFO')
export const GET_CREATOR_INFO_SUCCESS = createConst('GET_CREATOR_INFO_SUCCESS')
export const GET_CREATOR_INFO_FAILED = createConst('GET_CREATOR_INFO_FAILED')
export function getCreatorInfo(dummyObj) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_CREATOR_INFO })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1GetWithToken('/creator/info', undefined, userToken)
      dispatch({ type: GET_CREATOR_INFO_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GET_CREATOR_INFO_FAILED, error })
    }
  }
}

export const SET_CREATOR_INFO = createConst('SET_CREATOR_INFO')
export const SET_CREATOR_INFO_SUCCESS = createConst('SET_CREATOR_INFO_SUCCESS')
export const SET_CREATOR_INFO_FAILED = createConst('SET_CREATOR_INFO_FAILED')
export function setCreatorInfo({ creatorInfo }) {
  return async (dispatch, _getState, { i18n, toast }) => {
    dispatch({ type: SET_CREATOR_INFO })

    try {
      const userToken = getUserToken(_getState())
      const { data: response } = await v1PostWithToken(
        '/creator/info',
        omitBy({ creatorInfo }, isNil),
        userToken,
      )
      dispatch({ type: SET_CREATOR_INFO_SUCCESS, response })
      toast(i18n.t('common:儲存成功'))
    } catch (error) {
      dispatch({ type: SET_CREATOR_INFO_FAILED, error })
    }
  }
}

export const WITHDRAW_CASH = createConst('WITHDRAW_CASH')
export const WITHDRAW_CASH_SUCCESS = createConst('WITHDRAW_CASH_SUCCESS')
export const WITHDRAW_CASH_FAILED = createConst('WITHDRAW_CASH_FAILED')
export function withdrawCash({ nowWithdrawCash, withdrawFilePath }) {
  return async (dispatch, _getState, { i18n, toast }) => {
    dispatch({ type: WITHDRAW_CASH, nowWithdrawCash })

    try {
      const userToken = getUserToken(_getState())
      const { data: response } = await v1PostWithToken(
        '/creator/cash',
        omitBy({ nowWithdrawCash, withdrawFilePath }, isNil),
        userToken,
      )
      dispatch({ type: WITHDRAW_CASH_SUCCESS, response, nowWithdrawCash })
      if (response.code === 23001) {
        toast(i18n.t('common:撥款請求送出成功'))
      } else {
        toast(i18n.t('common:撥款失敗，請Email詢問我們'))
      }
    } catch (error) {
      dispatch({ type: WITHDRAW_CASH_FAILED, error, nowWithdrawCash })
    }
  }
}

export const GET_CASH_LIST = createConst('GET_CASH_LIST')
export const GET_CASH_LIST_SUCCESS = createConst('GET_CASH_LIST_SUCCESS')
export const GET_CASH_LIST_FAILED = createConst('GET_CASH_LIST_FAILED')
export function getCashList(dummyObj) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_CASH_LIST })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1GetWithToken('/creator/cash', undefined, userToken)
      dispatch({ type: GET_CASH_LIST_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GET_CASH_LIST_FAILED, error })
    }
  }
}
