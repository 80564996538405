import { createContext } from 'react'

interface DeviceContextValue {
  isApp: boolean
}

const DeviceContext = createContext<DeviceContextValue>({
  isApp: false,
})

export default DeviceContext
