import constantCreator from '../libs/constantCreator'
import { v1PostWithToken } from '../api/v1'
import { getUserToken } from '../currentUser/selector'

const createConst = constantCreator('events')

export const GET_EVENT_IG_LIST = createConst('GET_EVENT_IG_LIST')
export const GET_EVENT_IG_LIST_SUCCESS = createConst('GET_EVENT_IG_LIST_SUCCESS')
export const GET_EVENT_IG_LIST_FAILED = createConst('GET_EVENT_IG_LIST_FAILED')
export const fetchEventPageList = ({ type = '', clas = '', page = 9999999999999 }) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_EVENT_IG_LIST })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken(
        '/list/post',
        { type, clas, page },
        userToken,
      )
      dispatch({ type: GET_EVENT_IG_LIST_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GET_EVENT_IG_LIST_FAILED, error })
    }
  }
}
