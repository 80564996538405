import { Voidable } from '../types'
import { SignUpType } from './properties/UserProperty'

export function mapSignUpTypeFromUid(uid?: string): Voidable<SignUpType> {
  const splits: string[] = uid?.split('.')
  if (!splits) return undefined
  const signUpTypeMap = {
    sms: SignUpType.PHONE,
    email: SignUpType.EMAIL,
    fb: SignUpType.FACEBOOK,
    firebase: SignUpType.APPLE,
  }
  return signUpTypeMap[splits[0]]
}

const UserPropertyMapper = {
  mapSignUpTypeFromUid,
}
export default UserPropertyMapper
