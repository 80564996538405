import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#838eff',
      contrastText: '#fff',
    },
    secondary: {
      main: '#7D7AF4',
      contrastText: '#fff',
    },
  },
})

export const MuiThemeProvider = (props) => <ThemeProvider theme={muiTheme} {...props} />
