import React from 'react'
import { useTranslation } from '@i18n'
import { IndexLink } from './components/IndexLink'

export function UnknownError() {
  const { t } = useTranslation(['error'])
  return (
    <div tw="flex flex-col justify-center items-center p-20">
      <span tw="text-d1 font-mono font-bold mb-8">PopDaily</span>
      <p tw="text-center">
        {t('error', { context: 'unknown' })}
        <IndexLink>{t('goBackToIndex')}</IndexLink>
      </p>
    </div>
  )
}
