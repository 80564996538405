import { getGuestToken, validateToken } from '@app-core/api/v1'
import { setUser, logout, setUserToken, clearUserToken } from '@app-core/currentUser/actions'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const appWithCheckUser = (App) => {
  const EnhancedApp = (props) => {
    const dispatch = useDispatch()
    const isUnknownUser = useSelector<any, any>(
      (state) => state.currentUser.getIn(['userInfo', 'type']) === 'unknown',
    )

    useEffect(() => {
      if (!isUnknownUser) return

      checkUser().then(({ userInfo }) => {
        dispatch(setUser({ userInfo }))
      })
    }, [])

    return <App {...props} />
  }

  const checkUser = async (isCache = undefined, req = undefined, res = undefined) => {
    const tokenFromCookie = req?.cookies['user.token']
    // checkUser 有可能在 server side 與 client side 執行
    // 在 server side 會是 tokenFromCookie
    // 在 client side 會由瀏覽器 cookie
    // 下面 if 的條件僅判斷 isCache 即可

    if (!isCache) {
      const token: string = tokenFromCookie
      const { data } = await validateToken({ token })

      // type = guest => guest
      // code = 15002 => user
      // code = 52000 => token 驗證失敗

      // 是 guest 只拿守護動物照片
      if (data.type === 'guest') return { token, userInfo: { type: 'guest', img: data.img } }
      // 是 user 回傳 userInfo
      if (data.code === 15002) return { token, userInfo: data }
    }

    // 如果是 cache 或是 token 驗證失敗
    // 拿取 guess token
    const {
      data: { token },
      headers,
    } = await getGuestToken()
    // 重新認證 token，並且拿守護動物照片
    const { data } = await validateToken({ token })

    // 不是 cache 的話
    // 幫使用者登入
    if (!isCache && res) res.header('set-cookie', headers['set-cookie'])

    return { token, userInfo: { type: 'guest', img: data.img } }
  }

  EnhancedApp.getInitialProps = async ({ Component, ctx }) => {
    const { store, req, res } = ctx
    const isCache = req?.isCache ?? false
    // 如果 user 為 unknown 時 checkUser
    if (store.getState().currentUser.getIn(['userInfo', 'type']) === 'unknown') {
      const { token, userInfo } = await checkUser(isCache, req, res)

      // 在 server side 要 setToken
      if (token) store.dispatch(setUserToken(token))

      store.dispatch(setUser({ token, userInfo }))
    }

    const props = App.getInitialProps && (await App.getInitialProps({ Component, ctx }))

    // 如果是 cache 清空 store
    if (isCache) store.dispatch(logout())
    // 如果是 server side 清除 token
    if (req) store.dispatch(clearUserToken())

    return { ...props }
  }

  return EnhancedApp
}

export default appWithCheckUser
