import Immutable from 'immutable'
import * as ActionType from './actions'

export const initialState = Immutable.fromJS({
  dashbordInfo: {
    data: [],
    isLoading: true,
    errorMessage: '',
  },
  dailyPageviewInfo: {
    data: [],
    isLoading: true,
    errorMessage: '',
  },
  hotPost: {
    data: [],
    isLoading: true,
    errorMessage: '',
  },
  postList: {
    data: [],
    count: 0,
    isLoading: true,
    errorMessage: '',
  },
  revenueInfo: {
    totalPageview: 0,
    totalCash: 0,
    withdrawList: [],
    alreadyWithdrawCashSum: 0,
    isLoading: true,
    isLoadingWithdrawList: true,
    isLoadingWithdrawCash: false,
    errorMessage: '',
  },
  creatorInfo: {
    data: {},
    isLoading: true,
    isUpdating: false,
  },
})

export default function creatorReducer(state = initialState, action) {
  switch (action.type) {
    case '__NEXT_REDUX_WRAPPER_HYDRATE__':
      return Immutable.fromJS(action.payload.creator)
    case ActionType.GET_DASHBOARD_INFO:
      return state.setIn(['dashbordInfo', 'isLoading'], true)

    case ActionType.GET_DASHBOARD_INFO_SUCCESS:
      return state
        .setIn(['dashbordInfo', 'isLoading'], false)
        .setIn(['dashbordInfo', 'data'], Immutable.fromJS(action.response))

    case ActionType.GET_DASHBOARD_INFO_FAILED:
      return state.setIn(['dashbordInfo', 'isLoading'], false)

    case ActionType.GET_PAGEVIEW_DAILY:
      return state.setIn(['dailyPageviewInfo', 'isLoading'], true)

    case ActionType.GET_PAGEVIEW_DAILY_SUCCESS:
      return state
        .setIn(['dailyPageviewInfo', 'isLoading'], false)
        .setIn(['dailyPageviewInfo', 'data'], Immutable.fromJS(action.response.list))

    case ActionType.GET_PAGEVIEW_DAILY_FAILED:
      return state.setIn(['dailyPageviewInfo', 'isLoading'], false)

    case ActionType.GET_POST_LIST:
      return state
        .setIn([action.listType, 'isLoading'], true)
        .setIn([action.listType, 'data'], Immutable.fromJS([]))

    case ActionType.GET_POST_LIST_SUCCESS:
      return state
        .setIn([action.listType, 'isLoading'], false)
        .setIn([action.listType, 'count'], action.response.data.totalPostCount)
        .setIn([action.listType, 'data'], Immutable.fromJS(action.response.data.list))

    case ActionType.GET_POST_LIST_FAILED:
      return state.setIn([action.listType, 'isLoading'], false)

    case ActionType.SET_CREATOR_INFO:
      return state.setIn(['creatorInfo', 'isUpdating'], true)

    case ActionType.SET_CREATOR_INFO_SUCCESS:
      return state.setIn(['creatorInfo', 'isUpdating'], false)

    case ActionType.SET_CREATOR_INFO_FAILED:
      return state.setIn(['creatorInfo', 'isUpdating'], false)

    case ActionType.GET_CREATOR_INFO:
      return state.setIn(['creatorInfo', 'isLoading'], true)

    case ActionType.GET_CREATOR_INFO_SUCCESS:
      return state
        .setIn(['creatorInfo', 'isLoading'], false)
        .setIn(['creatorInfo', 'data'], Immutable.fromJS(action.response.creatorInfo))

    case ActionType.GET_CREATOR_INFO_FAILED:
      return state.setIn(['creatorInfo', 'isLoading'], false)

    case ActionType.GET_PAGEVIEW_SUM:
      return state.setIn(['revenueInfo', 'isLoading'], true)

    case ActionType.GET_PAGEVIEW_SUM_SUCCESS:
      return state
        .setIn(['revenueInfo', 'isLoading'], false)
        .setIn(['revenueInfo', 'totalPageview'], action.response.pageview)
        .setIn(['revenueInfo', 'totalCash'], action.response.totalRevenue)

    case ActionType.GET_PAGEVIEW_SUM_FAILED:
      return state.setIn(['revenueInfo', 'isLoading'], false)

    case ActionType.WITHDRAW_CASH:
      return state.setIn(['revenueInfo', 'isLoadingWithdrawCash'], true)

    case ActionType.WITHDRAW_CASH_SUCCESS: {
      /* codeFUN.CreatorCode.SUCCESS_WITHDRAW_CASH.code */
      if (action.response.code !== 23001) {
        return state.setIn(['revenueInfo', 'isLoadingWithdrawCash'], false)
      }
      return state
        .setIn(['revenueInfo', 'isLoadingWithdrawCash'], false)
        .updateIn(['revenueInfo', 'alreadyWithdrawCashSum'], (value) => {
          return value + Math.floor(action.nowWithdrawCash)
        })
        .updateIn(['revenueInfo', 'withdrawList'], (list) => {
          return list.unshift(Immutable.fromJS(action.response.data))
        })
    }

    case ActionType.WITHDRAW_CASH_FAILED:
      return state.setIn(['revenueInfo', 'isLoadingWithdrawCash'], false)

    case ActionType.GET_CASH_LIST:
      return state.setIn(['revenueInfo', 'isLoadingWithdrawList'], true)

    case ActionType.GET_CASH_LIST_SUCCESS:
      return state
        .setIn(['revenueInfo', 'isLoadingWithdrawList'], false)
        .setIn(['revenueInfo', 'withdrawList'], Immutable.fromJS(action.response.data))
        .setIn(['revenueInfo', 'alreadyWithdrawCashSum'], action.response.sum)

    case ActionType.GET_CASH_LIST_FAILED:
      return state.setIn(['revenueInfo', 'isLoadingWithdrawList'], false)

    default:
      return state
  }
}
