import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useAmp } from 'next/amp'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { getRouteName } from '@libs/webHelper'
import config from '@public-config'

type Props = {
  isApp: boolean
}

function GoogleTagManagerScriptEntry(props: Props) {
  const isAmp = useAmp()
  if (isAmp || !config.gtm.enabled) return null

  return (
    <>
      <script>window.dataLayer = window.dataLayer || []</script>
      <GoogleTagManagerScript {...props} />
    </>
  )
}

/**
 *
 * 我們是透過 Google Tag Manager (GTM) 與 Google Analytics (GA) 串接
 *
 * 其中若要搭配 GA 的 Custom Dimension 使用，步驟如下：
 *   1. 在 GA 新增 Custom Dimension，並確認 Dimension Index：https://analytics.google.com/analytics/web/#/a58961723w93030944p199475018/admin/custom-dimensions/
 *   2. 在 GTM 新增 Data Layer Variable，注意名稱要與下面 push 的一致：https://tagmanager.google.com/#/container/accounts/3073332214/containers/9675751/workspaces/1000203/variables
 *   3. 在需要帶入該 Custom Dimension 的 Tag 新增 Custom Dimensions，
 *      其中 `Index` 填寫 1. 的 Dimension Index，`Dimension value` 選擇 2. 建立的 data layer variable。
 *   4. 使用 GTM Preview 功能來檢查是否資料正確 (localhost 可)
 *
 * 參考範例：https://tagmanager.google.com/#/container/accounts/3073332214/containers/9675751/workspaces/1000203/tags/11
 * 參考文件：https://support.google.com/tagmanager/answer/6164990?hl=en
 *
 */
function GoogleTagManagerScript(props: Props) {
  const userId = useSelector<any>((state) => state.currentUser.get(['userInfo', 'mid']))
  const postData = useSelector<any, any>((state) => state.post.getIn(['post', 'data'])).toJS()
  const postsCategory = useSelector<any, any>((state) => state.posts.getIn(['posts', 'clas']))

  const contentType = postData.contentType
  const authorType = postData.authorRoles ? postData.authorRoles.join(',') : undefined
  const category = postData.clas?.[0] || postsCategory
  const r18Tag =
    postData.hashTags?.includes('色色話題') || postData.hashTags?.includes('更多色色話題')
  const router = useRouter()
  const routeName = getRouteName(router.pathname)

  useEffect(() => {
    // 全域變數
    window.category = category
    window.r18Tag = r18Tag
    window.routeName = routeName

    // 把 gtm.js 放到最前面，確保其他自定義事件可以被 gtm 讀到
    window.dataLayer = [{ 'gtm.start': new Date().getTime(), event: 'gtm.js' }, ...window.dataLayer]
    /**
     * ADM 是我們 GA 用來判別環境的自定值
     *   A - app
     *   D - desktop web
     *   M - mobile web
     *                                    Anna Wu
     */
    // eslint-disable-next-line no-nested-ternary
    const dimension9 = props.isApp ? 'App' : isMobile ? 'Mobile' : 'Desktop'
    // https://tagmanager.google.com/#/container/accounts/3073332214/containers/9675751/workspaces/1000203/variables/12
    window.dataLayer.push({ dimension9 })

    // https://tagmanager.google.com/#/container/accounts/3073332214/containers/9675751/workspaces/1000203/variables/804
    if (authorType) window.dataLayer.push({ authorType })

    // https://tagmanager.google.com/#/container/accounts/3073332214/containers/9675751/workspaces/1000203/variables/11
    if (userId) window.dataLayer.push({ userId: `popapp.${userId}` })

    // https://tagmanager.google.com/#/container/accounts/3073332214/containers/9675751/workspaces/1000203/variables/808
    if (contentType) window.dataLayer.push({ contentType })

    /**
     * 相關 Tag - https://tagmanager.google.com/#/container/accounts/3073332214/containers/9675751/workspaces/1000203/tags/11
     *
     * PS: custom event 要放最後，因為會需要先設定好前面的 data layer variables
     */
    /**
     * /embed 頁面不會打 page view
     */
    if (!window.location.pathname.startsWith('/embed')) {
      window.dataLayer.push({ event: 'initPageView' })
    }

    const script = document.createElement('script')
    script.async = true
    // https://tagmanager.google.com/#/container/accounts/3073332214/containers/9675751/workspaces/1000203?orgId=-7KM_NDeT0yzYPfUutdJ5w
    script.src = config.gtm.src
    document.body.appendChild(script)
  }, [])

  return (
    <>
      {/* GTM */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-N6NNZ59"
          style={{ display: 'none' }}
        />
      </noscript>
      {/* DFP */}
      <script
        key="DFP_BASE_SCRIPT"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function () {
            googletag.pubads().enableSingleRequest();
            googletag.pubads().collapseEmptyDivs();
            googletag.enableServices();
          });`,
        }}
      />
    </>
  )
}

export default GoogleTagManagerScriptEntry
