import Immutable from 'immutable'
import * as ActionType from './actions'
import * as ApiResponseCode from '../libs/ApiResponseCode'

import { adMasonry, webReplace, createSecretColor, listLikeUpdate } from './postTools'
import { GET_POST_SUCCESS } from '../post/actions'

export const initialState = Immutable.fromJS({
  posts: {
    type: 'explore', //explore, website
    mode: 'guess',
    clas: 'featured', //all or featured, hot, new, life, korea, ...
    carousel: {
      carouselNew: [],
      carouselPost: [],
      carouselSlider: [],
      isLoading: false,
      code: 90000,
      errorMessage: '',
    },
    list: [],
    currentList: [],
    page: -1,
    score: 99999,
    hasMore: true,
    isLoading: false,
    code: 90000,
    errorMessage: '',
  },
  topicList: {
    data: {},
    isLoading: false,
    errorMessage: '',
  },
  followerPosts: {
    list: [],
    hasMore: true,
    isLoading: false,
    page: -1,
  },
  recommendAccounts: {
    list: [],
    hasMore: true,
    isLoading: false,
    page: -1,
  },
  recommendPosts: {
    list: [],
    hasMore: true,
    isLoading: false,
    page: -1,
  },
})

export default function postsReducer(state = initialState, action) {
  const { postId, type = '', topics = [], payload = {}, response = {} } = action

  switch (type) {
    case '__NEXT_REDUX_WRAPPER_HYDRATE__':
      return Immutable.fromJS(action.payload.posts)

    case ActionType.RESET_LISTS:
      return state
        .setIn(['posts', 'list'], Immutable.fromJS([]))
        .setIn(['posts', 'currentList'], Immutable.fromJS([]))
        .setIn(['posts', 'page'], state.getIn(['posts', 'mode']) === 'hot' ? 0 : 9999999999999)
        .setIn(['posts', 'score'], 99999)
        .setIn(['posts', 'hasMore'], true)
        .setIn(['posts', 'isLoading'], false)
        .setIn(['posts', 'code'], 90000)
        .setIn(['posts', 'errorMessage'], '')

    case ActionType.RESET_POSTS:
      return state
        .setIn(['posts', 'carousel', 'carouselNew'], Immutable.fromJS([]))
        .setIn(['posts', 'carousel', 'carouselPost'], Immutable.fromJS([]))
        .setIn(['posts', 'carousel', 'carouselSlider'], Immutable.fromJS([]))
        .setIn(['posts', 'carousel', 'isLoading'], true)
        .setIn(['posts', 'carousel', 'code'], 90000)
        .setIn(['posts', 'carousel', 'errorMessage'], '')
        .setIn(['posts', 'list'], Immutable.fromJS([]))
        .setIn(['posts', 'currentList'], Immutable.fromJS([]))
        .setIn(['posts', 'page'], state.getIn(['posts', 'mode']) === 'hot' ? 0 : 9999999999999)
        .setIn(['posts', 'score'], 99999)
        .setIn(['posts', 'hasMore'], true)
        .setIn(['posts', 'isLoading'], false)
        .setIn(['posts', 'code'], 90000)
        .setIn(['posts', 'errorMessage'], '')

    case ActionType.POSTS_ARTICLE_LOADED:
      return payload.index ? state.setIn(['posts', 'list', payload.index, 'loaded'], true) : state

    case ActionType.SET_TYPE:
      return payload.type ? state.setIn(['posts', 'type'], payload.type) : state

    case ActionType.SET_CLAS:
      return payload.clas ? state.setIn(['posts', 'clas'], payload.clas) : state

    case ActionType.SET_MODE:
      return payload.mode
        ? state
            .setIn(['posts', 'mode'], payload.mode)
            .setIn(['posts', 'page'], payload.mode === 'hot' ? 0 : 9999999999999)
        : state

    case ActionType.GET_POST_LIST: {
      const { type, clas, mode, page, score } = action.payload || {}
      return state
        .setIn(['posts', 'type'], type || state.getIn(['posts', 'type']))
        .setIn(['posts', 'clas'], clas || state.getIn(['posts', 'clas']))
        .setIn(['posts', 'mode'], mode || state.getIn(['posts', 'mode']))
        .setIn(['posts', 'page'], page || state.getIn(['posts', 'page']))
        .setIn(['posts', 'score'], score || state.getIn(['posts', 'score']))
        .setIn(['posts', 'isLoading'], true)
        .setIn(['posts', 'code'], 90000)
        .setIn(['posts', 'errorMessage'], '')
        .updateIn(['posts', 'list'], (list) => {
          return action.page === 9999999999999 || action.page === 0 ? Immutable.fromJS([]) : list
        })
    }

    case ActionType.GET_POST_LIST_SUCCESS: {
      const { list = [], nextPage, code = 90000, msg = '' } = response
      const currentList = list.map(({ postID, t }) => ({ postID, t }))
      const mode = state.getIn(['posts', 'mode']) || 'new'
      const page = nextPage || list[list.length - 1]?.t || (mode === 'hot' ? 0 : 9999999999999)
      const score = Math.min(...list.map((el) => el.score))
      const hasMore = list.length >= 7

      let newState = state
        .setIn(['posts', 'isLoading'], false)
        .setIn(['posts', 'code'], code)
        .setIn(['posts', 'errorMessage'], msg)

      if (code === ApiResponseCode.GET_LIST_SUCCESS) {
        newState = newState
          .updateIn(['posts', 'list'], (_list) =>
            _list.concat(Immutable.fromJS(createSecretColor(list))),
          )
          .setIn(['posts', 'currentList'], Immutable.fromJS(currentList))
          .setIn(['posts', 'page'], page)
          .setIn(['posts', 'score'], score)
          .setIn(['posts', 'hasMore'], hasMore)
      }

      return newState
    }

    case ActionType.GET_POST_LIST_FAILED: {
      const { code = 90000, msg = '' } = response

      return state
        .setIn(['posts', 'code'], code)
        .setIn(['posts', 'errorMessage'], msg)
        .setIn(['posts', 'hasMore'], false)
        .setIn(['posts', 'isLoading'], false)
    }

    case ActionType.POST_LIKE_SUCCESS_DONE: {
      const { code = 90000 } = response
      const { postID = '' } = payload
      let newState = state
      if (code === ApiResponseCode.LIKE_SUCCESS && postID) {
        newState = state
          .updateIn(['posts', 'carousel', 'carouselNew'], listLikeUpdate(payload))
          .updateIn(['posts', 'carousel', 'carouselPost'], listLikeUpdate(payload))
          .updateIn(['posts', 'carousel', 'carouselSlider'], listLikeUpdate(payload))
          .updateIn(['posts', 'list'], listLikeUpdate(payload))
          .updateIn(['followerPosts', 'list'], listLikeUpdate(payload))
          .updateIn(['recommendPosts', 'list'], listLikeUpdate(payload))
      }
      return newState
    }

    case ActionType.GET_INDEX_CAROUSEL:
      return state
        .setIn(['posts', 'carousel', 'isLoading'], true)
        .setIn(['posts', 'carousel', 'code'], 90000)
        .setIn(['posts', 'carousel', 'errorMessage'], '')

    case ActionType.GET_INDEX_CAROUSEL_SUCCESS:
    case ActionType.GET_INDEX_CAROUSEL_FAILED: {
      let {
        list: { carouselNew = [], carouselPost = [], carouselSlider = [], carouselAD = [] } = {},
        code = 90000,
        msg = '',
      } = response

      carouselPost = carouselPost.filter((article) => article)
      carouselPost = carouselPost.concat(carouselNew.slice(0, 5 - carouselPost.length))
      // if carouselAD is not Empty Array then should add to carouselNew at 3,5,7.
      for (let ADIndex = 0; ADIndex < carouselAD.length; ADIndex++) {
        carouselNew.splice(ADIndex * 2 + 2, 0, carouselAD[ADIndex])
      }
      // handle post need random secret color
      carouselNew = createSecretColor(carouselNew)
      carouselPost = createSecretColor(carouselPost)
      carouselSlider = createSecretColor(carouselSlider)
      // handle web post
      carouselNew = carouselNew.map((article) => webReplace(article))
      carouselPost = carouselPost.map((article) => webReplace(article))
      carouselSlider = carouselSlider.map((article) => webReplace(article))

      let newState = state
        .setIn(['posts', 'carousel', 'isLoading'], false)
        .setIn(['posts', 'carousel', 'code'], code)
        .setIn(['posts', 'carousel', 'errorMessage'], msg)

      if (code === ApiResponseCode.GET_LIST_SUCCESS) {
        newState = newState
          .setIn(['posts', 'carousel', 'carouselNew'], Immutable.fromJS(carouselNew))
          .setIn(['posts', 'carousel', 'carouselPost'], Immutable.fromJS(carouselPost))
          .setIn(['posts', 'carousel', 'carouselSlider'], Immutable.fromJS(carouselSlider))
      }

      return newState
    }

    case ActionType.GET_TOPIC_LIST:
      return state.setIn(['topicList', 'isLoading'], true)

    case ActionType.GET_TOPIC_LIST_SUCCESS: {
      const topicList = response.sort((a, b) => (a.t || 0) - (b.t || 0))
      return state
        .setIn(['topicList', 'data', postId], Immutable.fromJS(topicList))
        .setIn(['topicList', 'isLoading'], false)
    }

    case ActionType.GET_TOPIC_LIST_FAILED:
      return state.setIn(['topicList', 'isLoading'], false)

    case ActionType.SET_TOPIC_LIST:
      return state.updateIn(['topicList', 'data', postId], () => {
        const topicList = action.topicList.sort((a, b) => (a.t || 0) - (b.t || 0))
        return Immutable.fromJS(topicList)
      })

    case ActionType.SET_POST_TO_TOPIC: {
      const topicListStateForThePost = state.getIn(['topicList', 'data', postId])
      if (!topicListStateForThePost) {
        return state
      }
      return state
        .setIn(['topicList', 'isLoading'], true)
        .updateIn(['topicList', 'data', postId], (list) => {
          let result = list
          for (let i = 0; i < topics.length; i++) {
            result = result.setIn([i, 'd'], topics[i].d)
          }
          return result
        })
    }

    case ActionType.SET_POST_TO_TOPIC_SUCCESS:
      return state

    case ActionType.SET_POST_TO_TOPIC_FAILED:
      return state.setIn(['topicList', 'isLoading'], false)

    case ActionType.GET_TRACKING_POST_LIST:
      return state.setIn(['followerPosts', 'isLoading'], true)

    case ActionType.GET_TRACKING_POST_LIST_SUCCESS:
      if (action.isRefresh) {
        return state
          .setIn(['followerPosts', 'list'], Immutable.fromJS(action.list))
          .setIn(['followerPosts', 'page'], action.page)
          .setIn(['followerPosts', 'hasMore'], action.hasMore)
          .setIn(['followerPosts', 'isLoading'], false)
      } else if (state.getIn(['followerPosts', 'page']) === action.page) {
        return state
      }
      return state
        .updateIn(['followerPosts', 'list'], (_list) => _list.concat(Immutable.fromJS(action.list)))
        .setIn(['followerPosts', 'page'], action.page)
        .setIn(['followerPosts', 'hasMore'], action.hasMore)
        .setIn(['followerPosts', 'isLoading'], false)

    case ActionType.GET_RECOMMEND_USERS:
      return state.setIn(['recommendAccounts', 'isLoading'], true)

    case ActionType.GET_RECOMMEND_USERS_SUCCESS:
      return state
        .setIn(['recommendAccounts', 'list'], Immutable.fromJS(action.list))
        .setIn(['recommendAccounts', 'isLoading'], false)

    case ActionType.GET_RECOMMEND_POST_LIST:
      return state.setIn(['recommendPosts', 'isLoading'], true)

    case ActionType.GET_RECOMMEND_POST_LIST_SUCCESS:
      if (action.isRefresh) {
        return state
          .setIn(['recommendPosts', 'list'], Immutable.fromJS(action.list))
          .setIn(['recommendPosts', 'page'], action.page)
          .setIn(['recommendPosts', 'hasMore'], action.hasMore)
          .setIn(['recommendPosts', 'isLoading'], false)
      } else if (state.getIn(['recommendPosts', 'page']) === action.page) {
        return state
      }
      return state
        .updateIn(['recommendPosts', 'list'], (_list) =>
          _list.concat(Immutable.fromJS(action.list)),
        )
        .setIn(['recommendPosts', 'page'], action.page)
        .setIn(['recommendPosts', 'hasMore'], action.hasMore)
        .setIn(['recommendPosts', 'isLoading'], false)

    case ActionType.UPDATE_RECOMMEND_USERS:
      return state.setIn(['recommendAccounts', 'list'], Immutable.fromJS(action.list))
    case ActionType.SAVE_ARTICLE_TO_TOPIC: {
      if (!state.getIn(['posts', 'list'])) {
        // no explore list
        return state
      }
      // const topicID = action.topicID
      return addArticleSavedCount(state, action.postID, 1)
    }
    case ActionType.UNSAVE_ARTICLE_TO_TOPIC: {
      if (!state.getIn(['posts', 'list'])) {
        // no explore list
        return state
      }
      // const topicID = action.topicID
      return addArticleSavedCount(state, action.postID, -1)
    }
    case GET_POST_SUCCESS: {
      const { postID, saved, like, reply } = response
      return findArticleAndUpdate(state, postID, (article) =>
        article.set('saved', saved).set('like', like).set('reply', reply),
      )
    }
    default:
      return state
  }
}

function findArticleAndUpdate(state, targetPostID, updateFunc) {
  return state.updateIn(['posts', 'list'], (list) => {
    return list.map((article) => {
      if (article.get('postID') !== targetPostID) {
        return article
      }
      return updateFunc(article)
    })
  })
}

function addArticleSavedCount(state, targetPostID, increment) {
  return findArticleAndUpdate(state, targetPostID, (article) => {
    return article.updateIn(['saved'], (savedCount) => savedCount + increment)
  })
}
