module.exports = {
  theme: {
    screens: {
      sm: '640px', // @media (min-width: 640px) { ... }
      md: '768px', // @media (min-width: 768px) { ... }
      lg: '1024px', // @media (min-width: 1024px) { ... }
      xl: '1280px', // @media (min-width: 1280px) { ... }
    },
    // https://www.figma.com/file/mT9xAv2y23QtCs4crf14CY/Color-and-Brand-2.0?node-id=1%3A71
    colors: {
      transparent: 'transparent',
      current: 'currentColor',

      black: '#000',
      white: '#fff',

      primary: {
        indigo: '#7D7AF4', // alias normal
        indigoGrey: '#B2B2FF', // alias grey
        yellow: '#FFC90B',
        normal: '#7D7AF4',
        grey: '#B2B2FF',
        light: '#F2F2FF',
        darkGrey: '#6056DB',
        300: '#C1C1FF',
      },

      secondary: {
        close: '#E23838',
        open: '#7D7AF4',
        normal: '#6666FF',
      },

      gray: {
        30: '#F7F7F7',
        100: '#E1E1E1',
        200: '#CCCCCC',
        400: '#999999',
        600: '#666666',
        800: '#333333',
      },

      avatar: {
        1: '#F2B3B3',
        2: '#F5D1AE',
        3: '#F0F0AA',
        4: '#CAEDA6',
        5: '#A7EBA7',
        6: '#A0E8C4',
        7: '#A4EDED',
        8: '#AACDF0',
        9: '#ADADF0',
        10: '#CBA8ED',
        11: '#E8ACE8',
        12: '#EDADCD',
      },

      wave: '#7D7AF4',
    },
    spacing: {
      px: '1px',
      0: '0',
      1: '0.25rem',   // 4px
      2: '0.5rem',    // 8px
      3: '0.75rem',   // 12px
      4: '1rem',      // 16px
      5: '1.25rem',   // 20px
      6: '1.5rem',    // 24px
      8: '2rem',      // 32px
      10: '2.5rem',   // 40px
      12: '3rem',     // 48px
      14: '3.5rem',   // 56px
      16: '4rem',     // 64px
      20: '5rem',     // 80px
      24: '6rem',     // 96px
      30: '7.5rem',   // 120px
      32: '8rem',     // 128px
      40: '10rem',    // 160px
      48: '12rem',    // 192px
      56: '14rem',    // 224px
      64: '16rem',    // 256px
      75: '18.75rem', // 300px
    },
    backgroundColor: (theme) => theme('colors'),
    backgroundPosition: {
      bottom: 'bottom',
      center: 'center',
      left: 'left',
      'left-bottom': 'left bottom',
      'left-top': 'left top',
      right: 'right',
      'right-bottom': 'right bottom',
      'right-top': 'right top',
      top: 'top',
    },
    backgroundSize: {
      auto: 'auto',
      cover: 'cover',
      contain: 'contain',
    },
    borderColor: (theme) => ({
      ...theme('colors'),
      default: 'currentColor',
    }),
    borderRadius: {
      none: '0',
      sm: '4px',
      default: '8px',
      md: '16px',
      lg: '20px',
      xl: '32px',
      full: '9999px',
      1: '4px',
      2: '8px',
      3: '12px',
      4: '16px',
      5: '20px',
      6: '24px',
      8: '32px',
    },
    borderWidth: {
      default: '1px',
      0: '0',
      2: '2px',
      4: '4px',
      8: '8px',
    },
    boxShadow: {
      xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
      sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      default: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
      outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
      none: 'none',
    },
    container: {},
    cursor: {
      auto: 'auto',
      default: 'default',
      pointer: 'pointer',
      wait: 'wait',
      text: 'text',
      move: 'move',
      'not-allowed': 'not-allowed',
    },
    fill: {
      current: 'currentColor',
    },
    flex: {
      1: '1 1 0%',
      auto: '1 1 auto',
      initial: '0 1 auto',
      none: 'none',
    },
    flexGrow: {
      0: '0',
      default: '1',
    },
    flexShrink: {
      0: '0',
      default: '1',
    },
    fontFamily: {
      sans: [
        'system-ui',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
      serif: ['Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif'],
      mono: ['Menlo', 'Monaco', 'Consolas', '"Liberation Mono"', '"Courier New"', 'monospace'],
    },
    fontSize: {
      // Mobile https://www.figma.com/file/hxz531mkvRlfSne6bR3Quv/App-Components-2.0?node-id=185%3A160
      // Desktop (suffixed with `-d`) https://www.figma.com/file/kg1wCHa2ZWS7mneaXyqyKc/Web-Components-2.0?node-id=25%3A82
      // d for "display"
      d1: '2.815rem', // '45px',
      'd1-d': '3.75rem', // '60px',
      d2: '2.25rem', // '36px',
      'd2-d': '3rem', // '48px',
      // h for "heading"
      h1: '1.75rem', // '28px',
      'h1-d': '2rem',
      h2: '1.5rem', // '24px',
      'h2-d': '1.75rem',
      h3: '1.375rem', // '22px',
      'h3-d': '1.5rem',
      h4: '1.25rem', // '20px',
      'h4-d': '1.375rem',
      h5: '1rem', // '16px',
      'h5-d': '1rem',
      h6: '.875rem', // '14px',
      'h6-d': '0.875rem',
      // b for "body"
      b1: '1rem', // '16px',
      'b1-d': '1rem', // '16px',
      b2: '.875rem', // '14px',
      'b2-d': '.875rem', // '14px',
      // c for "caption"
      c1: '.8125rem', // '13px',
      'c1-d': '.8125rem', // '13px',
      c2: '.75rem', // '12px',
      'c2-d': '.75rem', // '12px',
      article: '1.0625rem', // '17px',
      'article-d': '1.0625rem', // '17px',
      overline: '.75rem', // '12px',
      'overline-d': '.75rem', // '12px',
      small: '.75rem', // '12px',
      'small-d': '.75rem', // '12px',
      btn: '.875rem', // '14px',
      'btn-d': '.875rem', // '14px',
      'btn-lg': '1rem', // '16px',
      'btn-lg-d': '1rem', // '16px',
    },
    fontWeight: {
      hairline: '100',
      thin: '200',
      light: '300',
      normal: '400',
      medium: '500',
      semibold: '600',
      bold: '700',
      extrabold: '800',
      black: '900',
      400: '400',
      600: '600',
      700: '700',
    },
    height: (theme) => ({
      auto: 'auto',
      ...theme('spacing'),
      full: '100%',
      screen: '100vh',
    }),
    inset: {
      0: '0',
      auto: 'auto',
    },
    letterSpacing: {
      tighter: '-0.05em',
      tight: '-0.025em',
      normal: '0',
      wide: '0.025em',
      wider: '0.05em',
      widest: '0.1em',
      d1: '-1.8px',
      d2: '-1px',
      h1: '-0.5px',
      article: '-0.5px',
      overline: '2.5px',
    },
    lineHeight: {
      none: '1',
      d1: '1.378',
      'd1-d': '1.267',
      d2: '1.333',
      'd2-d': '1.375',
      h1: '1.446',
      'h1-d': '1.375',
      h2: '1.458',
      'h2-d': '1.428',
      h3: '1.65',
      'h3-d': '1.333',
      h4: '1.35',
      'h4-d': '1.2727',
      h5: '1.5',
      'h5-d': '1.25',
      h6: '1.42',
      'h6-d': '1.43',
      article: '1.65',
      'article-d': '1.65',
      b1: '1.5',
      'b1-d': '1.5',
      b2: '1.5',
      'b2-d': '1.43',
      c1: '1.538',
      'c1-d': '1.23',
      c2: '1.558',
      'c2-d': '1.333',
      overline: '1.833',
      'overline-d': '1.333',
      small: '1.833',
      'small-d': '1.333',
      btn: '1.143',
      'btn-d': '1',
      'btn-lg': '1.125',
      'btn-lg-d': '1',
    },
    listStyleType: {
      none: 'none',
      disc: 'disc',
      decimal: 'decimal',
    },
    margin: (theme, { negative }) => ({
      auto: 'auto',
      ...theme('spacing'),
      ...negative(theme('spacing')),
    }),
    maxHeight: {
      full: '100%',
      screen: '100vh',
    },
    maxWidth: (theme, { breakpoints }) => ({
      none: 'none',
      xs: '20rem',
      sm: '24rem',
      md: '28rem',
      lg: '32rem',
      xl: '36rem',
      '2xl': '42rem',
      '3xl': '48rem',
      '4xl': '56rem',
      '5xl': '64rem',
      '6xl': '72rem',
      full: '100%',
      ...breakpoints(theme('screens')),
    }),
    minHeight: {
      0: '0',
      full: '100%',
      screen: '100vh',
    },
    minWidth: {
      0: '0',
      full: '100%',
    },
    objectPosition: {
      bottom: 'bottom',
      center: 'center',
      left: 'left',
      'left-bottom': 'left bottom',
      'left-top': 'left top',
      right: 'right',
      'right-bottom': 'right bottom',
      'right-top': 'right top',
      top: 'top',
    },
    opacity: {
      0: '0',
      8: '0.08',
      25: '0.25',
      30: '0.3',
      50: '0.5',
      60: '0.6',
      80: '0.8',
      75: '0.75',
      90: '0.9',
      98: '0.98',
      100: '1',
    },
    order: {
      first: '-9999',
      last: '9999',
      none: '0',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
      11: '11',
      12: '12',
    },
    padding: (theme) => theme('spacing'),
    placeholderColor: (theme) => theme('colors'),
    stroke: {
      current: 'currentColor',
    },
    strokeWidth: {
      0: '0',
      1: '1',
      2: '2',
    },
    textColor: (theme) => theme('colors'),
    width: (theme) => ({
      auto: 'auto',
      ...theme('spacing'),
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      '1/12': '8.333333%',
      '2/12': '16.666667%',
      '3/12': '25%',
      '4/12': '33.333333%',
      '5/12': '41.666667%',
      '6/12': '50%',
      '7/12': '58.333333%',
      '8/12': '66.666667%',
      '9/12': '75%',
      '10/12': '83.333333%',
      '11/12': '91.666667%',
      full: '100%',
      screen: '100vw',
    }),
    zIndex: {
      auto: 'auto',
      0: '0',
      10: '10',
      20: '20',
      30: '30',
      40: '40',
      50: '50',
      mask: '1000',
      modal: '1001',
    },
    gap: (theme) => theme('spacing'),
    gridTemplateColumns: {
      none: 'none',
      1: 'repeat(1, minmax(0, 1fr))',
      2: 'repeat(2, minmax(0, 1fr))',
      3: 'repeat(3, minmax(0, 1fr))',
      4: 'repeat(4, minmax(0, 1fr))',
      5: 'repeat(5, minmax(0, 1fr))',
      6: 'repeat(6, minmax(0, 1fr))',
      7: 'repeat(7, minmax(0, 1fr))',
      8: 'repeat(8, minmax(0, 1fr))',
      9: 'repeat(9, minmax(0, 1fr))',
      10: 'repeat(10, minmax(0, 1fr))',
      11: 'repeat(11, minmax(0, 1fr))',
      12: 'repeat(12, minmax(0, 1fr))',
    },
    gridColumn: {
      auto: 'auto',
      'span-1': 'span 1 / span 1',
      'span-2': 'span 2 / span 2',
      'span-3': 'span 3 / span 3',
      'span-4': 'span 4 / span 4',
      'span-5': 'span 5 / span 5',
      'span-6': 'span 6 / span 6',
      'span-7': 'span 7 / span 7',
      'span-8': 'span 8 / span 8',
      'span-9': 'span 9 / span 9',
      'span-10': 'span 10 / span 10',
      'span-11': 'span 11 / span 11',
      'span-12': 'span 12 / span 12',
    },
    gridColumnStart: {
      auto: 'auto',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
      11: '11',
      12: '12',
      13: '13',
    },
    gridColumnEnd: {
      auto: 'auto',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
      11: '11',
      12: '12',
      13: '13',
    },
    gridTemplateRows: {
      none: 'none',
      1: 'repeat(1, minmax(0, 1fr))',
      2: 'repeat(2, minmax(0, 1fr))',
      3: 'repeat(3, minmax(0, 1fr))',
      4: 'repeat(4, minmax(0, 1fr))',
      5: 'repeat(5, minmax(0, 1fr))',
      6: 'repeat(6, minmax(0, 1fr))',
    },
    gridRow: {
      auto: 'auto',
      'span-1': 'span 1 / span 1',
      'span-2': 'span 2 / span 2',
      'span-3': 'span 3 / span 3',
      'span-4': 'span 4 / span 4',
      'span-5': 'span 5 / span 5',
      'span-6': 'span 6 / span 6',
    },
    gridRowStart: {
      auto: 'auto',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
    },
    gridRowEnd: {
      auto: 'auto',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
    },
    transformOrigin: {
      center: 'center',
      top: 'top',
      'top-right': 'top right',
      right: 'right',
      'bottom-right': 'bottom right',
      bottom: 'bottom',
      'bottom-left': 'bottom left',
      left: 'left',
      'top-left': 'top left',
    },
    scale: {
      0: '0',
      50: '.5',
      75: '.75',
      90: '.9',
      95: '.95',
      100: '1',
      105: '1.05',
      110: '1.1',
      125: '1.25',
      150: '1.5',
    },
    rotate: {
      '-180': '-180deg',
      '-90': '-90deg',
      '-45': '-45deg',
      0: '0',
      45: '45deg',
      90: '90deg',
      180: '180deg',
    },
    translate: (theme, { negative }) => ({
      ...theme('spacing'),
      ...negative(theme('spacing')),
      '-full': '-100%',
      '-1/2': '-50%',
      '1/2': '50%',
      full: '100%',
    }),
    skew: {
      '-12': '-12deg',
      '-6': '-6deg',
      '-3': '-3deg',
      0: '0',
      3: '3deg',
      6: '6deg',
      12: '12deg',
    },
    transitionProperty: {
      none: 'none',
      all: 'all',
      default:
        'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
      colors: 'background-color, border-color, color, fill, stroke',
      opacity: 'opacity',
      shadow: 'box-shadow',
      transform: 'transform',
    },
    transitionTimingFunction: {
      linear: 'linear',
      in: 'cubic-bezier(0.4, 0, 1, 1)',
      out: 'cubic-bezier(0, 0, 0.2, 1)',
      'in-out': 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    transitionDuration: {
      75: '75ms',
      100: '100ms',
      150: '150ms',
      200: '200ms',
      300: '300ms',
      500: '500ms',
      700: '700ms',
      1000: '1000ms',
    },
  },
}
