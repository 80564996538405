import { createWrapper, Context } from 'next-redux-wrapper'
import config from '@public-config'
import { i18n } from '@i18n'
import { toast } from 'react-toastify'
import { getRouteName } from '@libs/webHelper'
import { getUserLocation } from '@libs/getUserLocation'
import { destroyCookie } from '@libs/cookies'
import { isMobile } from 'react-device-detect'
import pageNavigation from '@libs/pageNavigation'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import authMiddleware from '@app-core/middlewares/auth'
import { getCookie } from '@libs/cookies'

import currentUser from '@app-core/currentUser/reducer'
import notify from '@app-core/notify/reducer'
import creator from '@app-core/creator/reducer'
import posts from '@app-core/posts/reducer'
import post from '@app-core/post/reducer'
import app from '@app-core/app/reducer'
import place from '@app-core/place/reducer'
import events from '@app-core/events/reducer'
import popbox from '@app-core/popbox/reducer'
import notifications from '@app-core/notifications/reducer'
import profile from '@app-core/profile/reducer'

function getIsApp() {
  if (typeof window === 'undefined') return false

  return getCookie('is_app') === 'true' || window.location.search.includes('?is_app=true')
}

const rootReducer = combineReducers({
  currentUser,
  notify,
  creator,
  app,
  posts,
  post,
  place,
  events,
  popbox,
  notifications,
  profile,
})

const makeStore = (_context: Context) => {
  const isLocal =
    config.myhost &&
    (config.myhost.indexOf('localhost') !== -1 || config.cookieDomain.indexOf('localhost') !== -1)

  const pushRoute = (path) => {
    if (window) {
      // TODO: (finalevil): 新版有登入頁以後要改回來 Router.pushRoute('/login')
      window.location.href = path
    }
  }

  const getDevicePrefixName = () => {
    if (typeof window === 'undefined') {
      return false
    }
    if (getIsApp()) {
      return 'A'
    } else if (isMobile) {
      return 'M'
    } else {
      return 'D'
    }
  }

  const middlewares = [
    thunkMiddleware.withExtraArgument({
      i18n,
      toast,
      destroyCookie,
      alert: typeof window === 'undefined' ? () => {} : window.alert,
      closeWindow: typeof window === 'undefined' ? () => {} : window.close,
      getRouteName,
      getUserLocation,
      isLocal,
      config,
      getDevicePrefixName,
      pageNavigation,
      pushRoute,
    }),
    authMiddleware({ i18n, pushRoute }),
  ]

  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)))

  return store
}

export const wrapper = createWrapper(
  makeStore,
  // { debug: true }
)

export const withReduxStore = wrapper.withRedux
