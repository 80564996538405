import React from 'react'
import getDisplayName from '@/hocs/getDisplayName'
import { NextPage } from 'next'
import { useNotification } from './useNotification'

export function withNotification(
  BaseComponent: NextPage<React.ComponentType>,
): React.ComponentType {
  function ResultComponent(props) {
    useNotification()
    return <BaseComponent {...props} />
  }
  ResultComponent.getInitialProps = BaseComponent.getInitialProps
  ResultComponent.displayName = getDisplayName(BaseComponent)

  return ResultComponent
}
