export enum EventNames {
  USER_PROFILE_VIEW = 'user-profile.view',
  USER_PROFILE_ARTICLE_LIST_ENTER_ARTICLE = 'user-profile.article-list.enter-article',
  USER_PROFILE_INFO_FOLLOW = 'user-profile.info.follow',
  USER_PROFILE_INFO_ENTER_FAN_LIST = 'user-profile.info.enter-fan-list',
  USER_PROFILE_INFO_ENTER_FOLLOWING_LIST = 'user-profile.info.enter-following-list',
  USER_PROFILE_ARTICLE_LIST_LIKE = 'user-profile.article-list.like',
  USER_PROFILE_COLLECTION_LIST_ENTER_COLLECTION_LIST = 'user-profile.collection-list.enter-collection-list',
  COLLECTION_LIST_VIEW = 'collection-list.view',
}

export enum FollowActionType {
  FOLLOW = 'follow',
  UNFOLLOW = 'unfollow',
}

export enum LikeActionType {
  LIKE = 'like',
  UNLIKE = 'unlike',
}

export enum PageComponents {
  INFO = 'info',
  TAB_BAR = 'tab-bar',
  ARTICLE_LIST = 'article-list',
  COLLECTION_LIST = 'collection-list',
}
