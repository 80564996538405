import axios from 'axios'
import Promise from 'bluebird'

let _timerIdForGetUserLocation = ''

export async function getUserLocation(isApp) {
  const getUserLocationFromIP = new Promise((resolve, reject) => {
    setTimeout(() => {
      axios
        .get('https://geoip-db.com/json/')
        .then((response) => {
          resolve({
            timestamp: new Date().getTime(),
            latitude: response.data.latitude,
            longitude: response.data.longitude,
          })
        })
        .catch(() => reject(new Error()))
    }, 1000)
  })
  if (isApp) {
    const location = await new Promise((resolve, reject) => {
      _timerIdForGetUserLocation = window.setInterval(() => {
        //postMessage('getMyLocation') 不斷去跟app要location資料
        //要完的座標會存到 window.lat 和 window.lng
        //不管是要回座標，或是得到錯誤都結束interval
        if (window.RNApp && window.RNApp.getMyLocation) {
          if (window.ReactNativeWebView) {
            //新RN版本  2020/04/03
            window.RNApp.getMyLocation()
          } else {
            //舊RN版本 2020/04/03
            const showPosition = (position) => {
              const { latitude, longitude } = position.coords
              window.lat = latitude
              window.lng = longitude
              window.errorMessageForGetUserLocation = false
            }
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(showPosition)
            } else {
              window.errorMessageForGetUserLocation = true
            }
          }
        }

        // TODO: window 這三個變數之後可以移除，為了支援舊版本 (2020/03/24)
        // 應該改用 redux 來做狀態綁定
        if ((window.lat && window.lng) || window.errorMessageForGetUserLocation) {
          window.clearInterval(_timerIdForGetUserLocation)
          if (window.errorMessageForGetUserLocation) {
            Promise.any([getUserLocationFromIP])
              .then((location) => resolve(location))
              .catch(Promise.AggregateError, (errArray) => {
                let error = { code: 'unknown' }
                errArray.forEach((e) => {
                  if (e.code) {
                    error = e
                  }
                })
                reject(error)
              })
          } else {
            resolve({
              latitude: window.lat,
              longitude: window.lng,
            })
          }
        }
      }, 500)
    })
    return location
  } else {
    const getUserLocationFromNavigator = new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) =>
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }),
        (error) => reject(error),
        { maximumAge: 300000 },
      )
    })
    const race = Promise.any([getUserLocationFromNavigator, getUserLocationFromIP])
    // Ken Kuan: I know this is strange. But if I use await Promise.any, the browser will complain Promise.any is not a function...
    const location = await race
    return location
  }
}
