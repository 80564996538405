import Immutable from 'immutable'
import * as ActionType from './actions'
import * as ApiResponseCode from '../libs/ApiResponseCode'

export const PlaceType = Object.freeze({
  choice: 'choice',
  ghost: 'ghost',
})

export const initialState = Immutable.fromJS({
  placeId: '',
  placeType: '',
  isLoading: true,
  errorMessage: '',
  places: {},
  totalCommentsCount: 0,
  comments: {},
  displayCommentIds: [],
  isCommentsLoading: false,
  hasMoreComments: false,
  currentUserCommentId: null,
  isCommentPopOpen: false,
  isCommentSubmitting: false,
  openCommentPopVia: '',
  editingCommentId: null,
  isPostsLoading: false,
  hasMorePosts: true,
  posts: {},
  displayPostIds: [],
  nextPostsPage: 9999999999999,
  nextPostsScore: 99999,
  postsSortingMethod: 'latest',
  recommendPlaceIds: [],
  isRecommendListLoading: false,
  placeList: {
    data: [],
    offset: 0,
    isLoading: true,
    errorMessage: '',
  },
  mapEventList: {
    data: [],
    isLoading: true,
    errorMessage: '',
  },
  mapPromote: {
    data: {},
    isLoading: true,
    errorMessage: '',
  },
  isSelectedCommentPopOpen: false,
  selectedCommentId: null,
  defaultTopic: null,
  hasGift: false,
  ghostRelatedPlaceIds: [],
})

export default function placeReducer(state = initialState, action) {
  switch (action.type) {
    case '__NEXT_REDUX_WRAPPER_HYDRATE__':
      return Immutable.fromJS(action.payload.place)

    case ActionType.INIT_PLACE: {
      const placeId = 'place.' + action.placeId
      const place = state.getIn(['places', placeId])
      let placeType = PlaceType.choice
      if (place) {
        placeType = place.getIn(['place', 'choice']) === 1 ? PlaceType.choice : PlaceType.ghost
      }
      return state
        .set('placeId', placeId)
        .set('placeType', placeType)
        .set('recommendPlaceIds', Immutable.fromJS([]))
        .set('ghostRelatedPlaceIds', Immutable.fromJS([]))
        .set('displayCommentIds', Immutable.fromJS([]))
        .set('totalCommentsCount', 0)
        .set('comments', null)
        .set('displayPostIds', Immutable.fromJS([]))
        .set('posts', Immutable.fromJS({}))
        .set('hasMorePosts', true)
        .set('nextPostsPage', 9999999999999)
        .set('nextPostsScore', 99999)
    }

    case ActionType.GET_PLACE:
      return state.setIn(['isLoading'], true)

    case ActionType.GET_PLACE_SUCCESS: {
      if (action.response.code === ApiResponseCode.GET_POST_SUCCESS) {
        if (Array.isArray(action.response.data)) {
          const data = action.response.data.reduce((obj, p) => ((obj[p.postID] = p), obj), {})
          return state.setIn(['isLoading'], false).mergeIn(['places'], Immutable.fromJS(data))
        } else {
          return state
            .setIn(['isLoading'], false)
            .setIn(['places', action.response.postID], Immutable.fromJS(action.response))
            .set('placeType', action.response.place.choice ? PlaceType.choice : PlaceType.ghost)
        }
      } else {
        return state.setIn(['isLoading'], false).setIn(['errorMessage'], action.response.msg)
      }
    }

    case ActionType.GET_PLACE_FAILED:
      return state
        .setIn(['isLoading'], false)
        .setIn(['errorMessage'], Immutable.fromJS(action.response))

    case ActionType.GET_PLACE_COMMENTS:
      return state.setIn(['isCommentsLoading'], true)

    case ActionType.GET_PLACE_COMMENTS_SUCCESS: {
      if (action.response.code === ApiResponseCode.GET_REPLY_SUCCESS) {
        const commentsLength = 4
        const likeReplyIds = action.response.like.map((l) => l.replyID)
        const comments = Immutable.fromJS(
          action.response.list.reduce((obj, c) => {
            c.currentUserLike = likeReplyIds.includes(c.replyID)
            return (obj[c.id] = c), obj
          }, {}),
        )
        const displayCommentIds = sortedCommentsId(comments, commentsLength)
        const hasMoreComments = comments.count() > commentsLength
        const userComment = comments.find(
          (comment) => comment.getIn(['userInfo', 'mid']) === action.userId,
        )
        const userCommentId = userComment && userComment.get('id')
        return state
          .setIn(['isCommentsLoading'], false)
          .setIn(['totalCommentsCount'], comments.count())
          .setIn(['comments'], comments)
          .setIn(['displayCommentIds'], displayCommentIds)
          .setIn(['hasMoreComments'], hasMoreComments)
          .setIn(['currentUserCommentId'], userCommentId)
      } else {
        // TODO: Handle error
        return state
      }
    }

    case ActionType.GET_PLACE_COMMENTS_FAILED:
      return state.setIn(['isCommentsLoading'], false)

    case ActionType.EXPAND_PLACE_COMMENTS: {
      const comments = state.get('comments')
      const displayCommentIds = sortedCommentsId(comments, state.get('totalCommentsCount'))
      return state.setIn(['displayCommentIds'], displayCommentIds).setIn(['hasMoreComments'], false)
    }

    case ActionType.OPEN_COMMENT_POP: {
      return state
        .set('isCommentPopOpen', true)
        .set('editingCommentId', action.commentId)
        .set('openCommentPopVia', action.via)
    }

    case ActionType.CLOSE_COMMENT_POP:
      return state.setIn(['isCommentPopOpen'], false)

    case ActionType.SUBMIT_COMMENT:
      return state.setIn(['isCommentSubmitting'], true)

    case ActionType.SUBMIT_COMMENT_SUCCESS:
      if (
        action.response.code === ApiResponseCode.SUBMIT_COMMENT_SUCCESS ||
        action.response.code === ApiResponseCode.EDIT_COMMENT_SUCCESS
      ) {
        const placeId = state.get('placeId')
        const currentUserCommentId = state.get('currentUserCommentId')
        const oldClap = currentUserCommentId
          ? state.getIn(['comments', currentUserCommentId]).get('clap')
          : 0
        const diffClap = action.clap - oldClap
        const newClap = (state.getIn(['places', placeId, 'place', 'clap', 'score']) || 0) + diffClap
        return state.withMutations((state) => {
          state
            .set('isCommentSubmitting', false)
            .set('isCommentPopOpen', false)
            .setIn(['places', placeId, 'place', 'clap', 'score'], newClap)
        })
      } else {
        // TODO: handle error
        return state
      }

    case ActionType.REPORT_PLACE_COMMENT_SUCCESS:
      return state

    case ActionType.SUBMIT_COMMENT_FAILED:
      return state.setIn(['isCommentSubmitting'], false)

    case ActionType.SEARCH_PLACE:
      return state
        .setIn(['placeList', 'isLoading'], true)
        .updateIn(['placeList', 'data'], (list) => {
          return action.offset === 0 ? Immutable.fromJS([]) : list
        })

    case ActionType.SEARCH_PLACE_SUCCESS:
      return state
        .setIn(['placeList', 'isLoading'], false)
        .setIn(['placeList', 'offset'], action.offset)
        .updateIn(['placeList', 'data'], (list) => {
          return action.offset === 0
            ? Immutable.fromJS(action.response.result)
            : list.concat(Immutable.fromJS(action.response.result)).toOrderedSet().toList()
        })

    case ActionType.SEARCH_PLACE_FAILED:
      return state.setIn(['placeList', 'isLoading'], false)

    case ActionType.GET_MAP_EVENT_LIST:
      return state
        .setIn(['mapEventList', 'isLoading'], true)
        .setIn(['mapEventList', 'data'], Immutable.fromJS([]))

    case ActionType.GET_MAP_EVENT_LIST_SUCCESS:
      return state
        .setIn(['mapEventList', 'isLoading'], false)
        .setIn(['mapEventList', 'data'], Immutable.fromJS(action.response.result))

    case ActionType.GET_MAP_EVENT_LIST_FAILED:
      return state.setIn(['mapEventList', 'isLoading'], false)

    case ActionType.GET_MAP_PROMOTE:
      return state
        .setIn(['mapPromote', 'isLoading'], true)
        .setIn(['mapPromote', 'data'], Immutable.fromJS({}))

    case ActionType.GET_MAP_PROMOTE_SUCCESS:
      return state
        .setIn(['mapPromote', 'isLoading'], false)
        .setIn(['mapPromote', 'data'], Immutable.fromJS(action.response.data.food))

    case ActionType.GET_MAP_PROMOTE_FAILED:
      return state.setIn(['mapPromote', 'isLoading'], false)

    case ActionType.GET_PLACE_POSTS:
      return state.setIn(['isPostsLoading'], true)

    case ActionType.GET_PLACE_POSTS_SUCCESS: {
      if (action.response.code === ApiResponseCode.GET_LIST_SUCCESS) {
        const list = action.response.list
        const nextPostsPage = Math.min(...list.map((l) => l.t))
        const nextPostsScore = Math.min(...list.map((l) => l.score))
        const posts = Immutable.fromJS(
          list.reduce((obj, post) => ((obj[post.postID] = post), obj), {}),
        )
        const displayPostIds = Immutable.fromJS(list.map((p) => p.postID))
        const hasMorePosts = list.length >= 7
        return state.withMutations((state) => {
          state
            .setIn(['isPostsLoading'], false)
            .setIn(['hasMorePosts'], hasMorePosts)
            .setIn(['nextPostsPage'], nextPostsPage)
            .setIn(['nextPostsScore'], nextPostsScore)
            .updateIn(['displayPostIds'], (ids) => ids.concat(displayPostIds))
            .mergeIn(['posts'], posts)
        })
      } else {
        // TODO: Handle error
        return state
      }
    }

    case ActionType.GET_PLACE_POSTS_FAILED: {
      return state.setIn(['isPostsLoading'], false)
    }

    case ActionType.LIKE_COMMENT: {
      const { comment, like } = action
      return state.updateIn(['comments', comment.id], (c) => {
        return c
          .updateIn(['like'], (l) => l + (like ? 1 : -1))
          .set('currentUserLike', Boolean(like))
      })
    }

    case ActionType.SELECT_COMMENT: {
      const { comment } = action
      return state
        .setIn(['isSelectedCommentPopOpen'], true)
        .setIn(['selectedCommentId'], comment.get('id'))
    }

    case ActionType.CLOSE_SELECTED_COMMENT_POP: {
      return state.setIn(['isSelectedCommentPopOpen'], false)
    }

    case ActionType.DELETE_COMMENT_SUCCESS: {
      const { commentId } = action
      return state.withMutations((state) => {
        return state
          .deleteIn(['comments', commentId])
          .set('selectedCommentId', null)
          .set('currentUserCommentId', null)
          .updateIn(['displayCommentIds'], (commentIds) => {
            const index = commentIds.indexOf(commentId)
            if (index !== -1) {
              return commentIds.delete(index)
            }
            return commentIds
          })
      })
    }

    case ActionType.GET_GIFT_SUCCESS: {
      const {
        response: { status, data = {} },
      } = action
      return state
        .set('hasGift', status === 'success')
        .set('giftId', data.giftID)
        .set('giftData', data)
    }

    case ActionType.GET_RECOMMEND_LIST: {
      return state.set('isRecommendListLoading', true)
    }

    case ActionType.GET_RECOMMEND_LIST_SUCCESS: {
      const recommendPlaceIds = action.response.map((p) => p.postID)
      const places = action.response.reduce((obj, p) => ((obj[p.postID] = p), obj), {})
      return state
        .set('isRecommendListLoading', false)
        .mergeIn(['places'], Immutable.fromJS(places))
        .set(action.requestType, Immutable.fromJS(recommendPlaceIds))
    }

    case ActionType.GET_RECOMMEND_LIST_FAILED: {
      // TODO: show error
      return state.set('isRecommendListLoading', false)
    }

    default:
      return state
  }
}

function sortedCommentsId(comments, length) {
  return comments
    .sort((a, b) => b.get('t') - a.get('t'))
    .valueSeq()
    .map((c) => c.get('id'))
    .take(length)
    .toList()
}
