const routeNameMap = {
  '/': 'explore',
  '/explore/[clas]': 'explore',
  '/[website]': 'website',
  '/[website]/[postID]': 'websitePost',
  '/forum/[forum]/[postID]': 'forumPost',
  '/place/[placeId]': 'place',
  '/map': 'map',
}

export const getRouteName = (pathname) => {
  if (typeof window === 'undefined') return ''
  if (!pathname) return window.location.pathname
  return routeNameMap[pathname]
}

export const checkIsIndex = (state, router) => {
  const type = state.posts.getIn(['posts', 'type'])
  const clas = state.posts.getIn(['posts', 'clas'])
  const {
    asPath = '',
    query: { clas: qClas = '' },
  } = router
  return (
    asPath === '/' ||
    asPath.includes('/explore/featured') ||
    qClas === 'featured' ||
    (type === 'explore' && clas === 'featured')
  )
}
