import { v1PostWithToken } from '@app-core/api'
import { getUserToken } from '@app-core/currentUser/selector'
import constantCreator from '@app-core/libs/constantCreator'
import { FollowActionType, LikeActionType } from '@libs/eventTracking/profile/common'
import { trackUserProfileArticleListLike } from '@libs/eventTracking/profile/user-profile.article-list.like'
import { trackUserProfileInfoFollowEvent } from '@libs/eventTracking/profile/user-profile.info.follow'

const createConst = constantCreator('post')

export const GET_USER_INFO = createConst('GET_USER_INFO')
export const GET_USER_INFO_SUCCESS = createConst('GET_USER_INFO_SUCCESS')
export const GET_USER_INFO_FAILED = createConst('GET_USER_INFO_FAILED')

// type getUserInfoFunctionType = 'user' | 'me' | 'guest'
export const getUserInfoByGql = ({ getUserInfoFunction, getUserInfoFunctionType }) => {
  return async (dispatch) => {
    dispatch({ type: GET_USER_INFO })
    try {
      const { data, error: gqlError } = await getUserInfoFunction()
      if (gqlError) {
        const error = new Error(gqlError.message)
        throw error
      }
      dispatch({ type: GET_USER_INFO_SUCCESS, response: data[getUserInfoFunctionType] })
    } catch (error) {
      dispatch({ type: GET_USER_INFO_FAILED, response: error })
      // Throw error for the outside catch
      throw error
    }
  }
}

export const SET_IS_ME_ROUTE = createConst('SET_IS_ME_ROUTE')
export const SET_IS_GUEST = createConst('SET_IS_GUEST')
export const SET_IS_SELF = createConst('SET_IS_SELF')

export const setIsMeRoute = (payload = { isMeRoute: false }) => ({ type: SET_IS_ME_ROUTE, payload })
export const setIsGuest = (payload = { isGuest: false }) => ({ type: SET_IS_GUEST, payload })
export const setIsSelf = (payload = { isSelf: false }) => ({ type: SET_IS_SELF, payload })

export const FOLLOW = createConst('FOLLOW')
export const FOLLOW_SUCCESS = createConst('FOLLOW_SUCCESS')
export const FOLLOW_FAILED = createConst('FOLLOW_FAILED')
export const userFollow = ({ followID = '', follow = 1 }) => {
  return async (dispatch, getState) => {
    const isUserFollowMutationLoading = getState().profile.getIn(['followMutation', 'isLoading'])
    if (isUserFollowMutationLoading) {
      return
    }
    dispatch({ type: FOLLOW })
    trackUserProfileInfoFollowEvent(
      followID,
      follow === 1 ? FollowActionType.FOLLOW : FollowActionType.UNFOLLOW,
    )
    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken(
        '/user/follow',
        { followID, follow },
        userToken,
      )
      dispatch({ type: FOLLOW_SUCCESS, response })
    } catch (e) {
      dispatch({ type: FOLLOW_FAILED, response: e })
    }
  }
}

export const GET_USER_POST_LIST = createConst('GET_USER_POST_LIST')
export const GET_USER_POST_LIST_SUCCESS = createConst('GET_USER_POST_LIST_SUCCESS')
export const GET_USER_POST_LIST_FAILED = createConst('GET_POST_LIST_FAILED')

export const SET_USER_POSTS_TYPE = createConst('SET_USER_POSTS_TYPE')
export const setUserPostsType = (payload = { type: 'user' }) => ({
  type: SET_USER_POSTS_TYPE,
  payload,
})

export const SET_USER_POSTS_CLAS = createConst('SET_USER_POSTS_CLAS')
export const setUserPostsClas = (payload = { clas: '' }) => ({
  type: SET_USER_POSTS_CLAS,
  payload,
})

export const SET_USER_POSTS_MODE = createConst('SET_USER_POSTS_MODE')
export const setUserPostsMode = (payload = { mode: 'new' }) => ({
  type: SET_USER_POSTS_MODE,
  payload,
})

export const getIndexUserPostList = ({
  type = '',
  clas = '',
  mode = '',
  page = -1,
  score = 99999,
  param = '',
  touch = [],
}) => {
  return async (dispatch, _getState, { isLocal, getDevicePrefixName }) => {
    const defaultPage = mode === 'hot' ? 0 : 9999999999999
    const options = {
      isLocal,
      isApp: getDevicePrefixName() === 'A',
      page,
    }
    dispatch({ type: GET_USER_POST_LIST, ...options })
    try {
      const userToken = getUserToken(_getState())
      const { data: response } = await v1PostWithToken(
        '/list/post',
        {
          type,
          clas,
          mode,
          page: page !== -1 ? page : defaultPage,
          score,
          param,
          touch,
        },
        userToken,
      )
      dispatch({ type: GET_USER_POST_LIST_SUCCESS, response, ...options })
    } catch (error) {
      dispatch({ type: GET_USER_POST_LIST_FAILED, error, ...options })
    }
  }
}

export const getInitialUserPostList = ({
  type = '',
  clas = '',
  mode = '',
  page = -1,
  score = 99999,
  param = '',
  touch = [],
}) => async (dispatch, getState) => {
  try {
    const defaultPage = mode === 'hot' ? 0 : 9999999999999
    const data = {
      type,
      clas,
      mode,
      page: page !== -1 ? page : defaultPage,
      score,
      param,
      touch,
    }
    const url = '/list/post'
    dispatch({ type: GET_USER_POST_LIST })

    const userToken = getUserToken(getState())
    const res = await v1PostWithToken(url, data, userToken)

    if (res.data.list.length === 0) {
      return dispatch({
        type: GET_USER_POST_LIST_FAILED,
        response: { msg: 'No Posts', code: res.data.code },
      })
    }
    return dispatch({ type: GET_USER_POST_LIST_SUCCESS, response: res.data })
  } catch (error) {
    return dispatch({
      type: GET_USER_POST_LIST_FAILED,
      response: { msg: 'error in action creator (getInitialPostList)' },
    })
  }
}

export const GET_USER_COLLECTION_LIST = createConst('GET_USER_COLLECTION_LIST')
export const GET_USER_COLLECTION_LIST_SUCCESS = createConst('GET_USER_COLLECTION_LIST_SUCCESS')
export const GET_USER_COLLECTION_LIST_FAILED = createConst('GET_COLLECTION_LIST_FAILED')

export const SET_USER_COLLECTIONS_TYPE = createConst('SET_USER_COLLECTIONS_TYPE')
export const setUserCollectionsType = (payload = { type: 'user' }) => ({
  type: SET_USER_COLLECTIONS_TYPE,
  payload,
})

export const SET_USER_COLLECTIONS_CLAS = createConst('SET_USER_COLLECTIONS_CLAS')
export const setUserCollectionsClas = (payload = { clas: '' }) => ({
  type: SET_USER_COLLECTIONS_CLAS,
  payload,
})

export const SET_USER_COLLECTIONS_MODE = createConst('SET_MODE')
export const setUserCollectionsMode = (payload = { mode: 'new' }) => ({
  type: SET_USER_COLLECTIONS_MODE,
  payload,
})

export const getIndexUserCollectionList = ({
  type = '',
  clas = '',
  mode = '',
  page = -1,
  score = 99999,
  param = 'showtopic',
  touch = [],
}) => {
  return async (dispatch, _getState, { isLocal, getDevicePrefixName }) => {
    const defaultPage = mode === 'hot' ? 0 : 9999999999999
    const options = {
      isLocal,
      isApp: getDevicePrefixName() === 'A',
      page,
    }
    dispatch({ type: GET_USER_COLLECTION_LIST, ...options })
    try {
      const userToken = getUserToken(_getState())
      const { data: response } = await v1PostWithToken(
        '/list/post',
        {
          type,
          clas,
          mode,
          page: page !== -1 ? page : defaultPage,
          score,
          param,
          touch,
        },
        userToken,
      )
      dispatch({ type: GET_USER_COLLECTION_LIST_SUCCESS, response, ...options })
    } catch (error) {
      dispatch({ type: GET_USER_COLLECTION_LIST_FAILED, error, ...options })
    }
  }
}

export const getInitialUserCollectionList = ({
  type = '',
  clas = '',
  mode = '',
  page = -1,
  score = 99999,
  param = 'showtopic',
  touch = [],
}) => async (dispatch, getState) => {
  try {
    const defaultPage = mode === 'hot' ? 0 : 9999999999999
    const data = {
      type,
      clas,
      mode,
      page: page !== -1 ? page : defaultPage,
      score,
      param,
      touch,
    }
    const url = '/list/post'
    dispatch({ type: GET_USER_COLLECTION_LIST })

    const userToken = getUserToken(getState())
    const res = await v1PostWithToken(url, data, userToken)

    if (res.data.list.length === 0) {
      return dispatch({
        type: GET_USER_COLLECTION_LIST_FAILED,
        response: { msg: 'No Posts', code: res.data.code },
      })
    }
    return dispatch({ type: GET_USER_COLLECTION_LIST_SUCCESS, response: res.data })
  } catch (error) {
    return dispatch({
      type: GET_USER_POST_LIST_FAILED,
      response: { msg: 'error in action creator (getInitialPostList)' },
    })
  }
}

export const POST_LIKE = createConst('POST_LIKE')
export const POST_LIKE_SUCCESS = createConst('POST_LIKE_SUCCESS')
export const POST_LIKE_SUCCESS_DONE = createConst('POST_LIKE_SUCCESS_DONE')
export const POST_LIKE_ERROR = createConst('POST_LIKE_SUCCESS_ERROR')
export const postLike = ({ postID = '', replyID = 0, isLike: like }) => {
  return async (dispatch, getState) => {
    const isUserPostListMutationLoading = getState().profile.getIn([
      'postLikeMutation',
      'isLoading',
    ])
    if (isUserPostListMutationLoading) {
      return
    }
    const url = '/post/like'
    const body = { postID, like }

    dispatch({ type: POST_LIKE })
    trackUserProfileArticleListLike({
      postID,
      actionType: like === '0' ? LikeActionType.UNLIKE : LikeActionType.LIKE,
    })
    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken(url, body, userToken)
      dispatch({ type: POST_LIKE_SUCCESS, response })
      dispatch(postLikeDone(POST_LIKE_SUCCESS_DONE, like, postID, replyID, response))
      return { postID, replyID, like }
    } catch (error) {
      dispatch(postLikeFail(error))
      throw error
    }
  }
}
export function postLikeDone(type, like, postID, replyID, response) {
  return { type, payload: { postID, replyID, like }, response }
}
export function postLikeFail(error) {
  return { type: POST_LIKE_ERROR, payload: { error } }
}
