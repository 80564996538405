import { useDispatch } from 'react-redux'
import { setOnesignalPushID } from '@app-core/notifications/actions'
import { useEffect, useState } from 'react'
import OneSignal, { useOneSignalSetup } from 'react-onesignal'
import { setOneSignalUserIdToTrackers } from '@libs/eventTracking'

export function useNotification() {
  const [playerId, setPlayId] = useState<string>()
  const dispatch = useDispatch()

  useOneSignalSetup(async () => {
    const playerId = await OneSignal.getPlayerId()
    setPlayId(playerId)
  })

  useEffect(() => {
    if (playerId) {
      dispatch(setOnesignalPushID({ onesignalID: playerId }))
      setOneSignalUserIdToTrackers(playerId)
    }
  }, [playerId, dispatch])
}
