import Immutable from 'immutable'
import * as ActionType from './actions'
import * as ApiResponseCode from '../libs/ApiResponseCode'

export const initialState = Immutable.fromJS({
  userId: '',
  userToken: '',
  userTid: '',
  errorMessage: '',
  isLoading: false,
  userLocation: {
    lat: 0,
    lng: 0,
    isLoading: false,
  },
  userPermission: {
    data: [],
    isLoading: true,
    errorMessage: '',
  },
  userInfo: {
    type: 'unknown',
    mid: 0,
    img: '',
    name: '',
    valid: false,
  },
})

export default function currentUserReducer(state = initialState, action) {
  switch (action.type) {
    case '__NEXT_REDUX_WRAPPER_HYDRATE__':
      return Immutable.fromJS(action.payload.currentUser)

    case ActionType.SET_USER: {
      const { userInfo, token } = action.payload
      return state.set('userInfo', Immutable.fromJS(userInfo)).set('userToken', token)
    }
    case ActionType.GET_USER_LOCATION:
      return state.setIn(['userLocation', 'isLoading'], true)

    case ActionType.GET_USER_LOCATION_SUCCESS:
      return state
        .setIn(['userLocation', 'isLoading'], false)
        .setIn(['userLocation', 'lat'], action.location.latitude)
        .setIn(['userLocation', 'lng'], action.location.longitude)

    case ActionType.GET_USER_LOCATION_FAILED:
      return state.setIn(['userLocation', 'isLoading'], false)

    case ActionType.GUEST_LOGIN:
      return state.set('isLoading', true)

    case ActionType.LOGOUT: {
      return initialState
    }

    case ActionType.GUEST_LOGIN_SUCCESS: {
      const { code, token, uid } = action.response
      if (code === ApiResponseCode.GET_GUEST_TOKEN_SUCCESS && token) {
        return state.set('isLoading', false).set('userId', uid).set('userToken', token)
      }
      return state
    }

    case ActionType.CHECK_USER_TOKEN_SUCCESS: {
      const { code, type = 'guest', mid = '', img = '', name = '' } = action.response
      if (code === ApiResponseCode.USERINFO_GET_SUCCESS) {
        return state
          .setIn(['userInfo', 'type'], type)
          .setIn(['userInfo', 'mid'], mid)
          .setIn(['userInfo', 'img'], img)
          .setIn(['userInfo', 'name'], name)
      }
      if (
        !(
          code === ApiResponseCode.TOKEN_CORRECT ||
          code === ApiResponseCode.GUEST_TOKEN_SUCCESS ||
          code === ApiResponseCode.USERINFO_GET_SUCCESS
        )
      ) {
        return state.set('userId', '').set('userToken', '')
      }
      return state
    }

    case ActionType.GET_USER_PERMISSION:
      return state.setIn(['userPermission', 'isLoading'], true)

    case ActionType.GET_USER_PERMISSION_SUCCESS:
      return state
        .setIn(
          ['userPermission', 'data'],
          Immutable.fromJS(
            !action.response.items ? action.response.permission : action.response.items,
          ),
        )
        .setIn(['userPermission', 'isLoading'], false)
        .setIn(['userPermission', 'errorMessage'], action.response.msg ? action.response.msg : '')

    //for app
    case ActionType.SET_USER_INFO: {
      const merging = Immutable.Map({
        ...action.userInfo,
      })
      return initialState.merge(merging)
    }
    case ActionType.SET_MY_LOCATION:
      return state.setIn(['lat'], action.latitude).setIn(['lng'], action.longitude)

    case ActionType.CLEAR_USER_LOCATION:
      return state
        .setIn(['lat'], null)
        .setIn(['lng'], null)
        .setIn(['isLoadingForGetUserLocation'], false)
        .setIn(['errorMessageForGetUserLocation'], null)

    case ActionType.GET_USER_LOCATION_IN_APP:
      return state
        .setIn(['isLoadingForGetUserLocation'], true)
        .setIn(['errorMessageForGetUserLocation'], '')

    case ActionType.GET_USER_LOCATION_IN_APP_SUCCESS:
      return state
        .setIn(['lat'], action.latitude)
        .setIn(['lng'], action.longitude)
        .setIn(['isLoadingForGetUserLocation'], false)

    case ActionType.GET_USER_LOCATION_IN_APP_FAILED:
      return state
        .setIn(['isLoadingForGetUserLocation'], false)
        .setIn(['errorMessageForGetUserLocation'], action.errorMessage)
    case ActionType.SET_USER_TOKEN:
      return state.set('userToken', action.payload)
    case ActionType.CLEAR_USER_TOKEN:
      return state.set('userToken', '')
    default:
      return state
  }
}
