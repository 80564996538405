import { fetchPost, fetchReply, fetchTopicList, postComment, v1PostWithToken } from '../api/v1'
import { openConfirmDialog } from '../app/actions'
import constantCreator from '../libs/constantCreator'
import { GET_TOPIC_LIST, GET_TOPIC_LIST_SUCCESS, GET_TOPIC_LIST_FAILED } from '../posts/actions'
import { detectPopdailyAccount } from '../posts/postTools'
import * as ApiResponseCode from '../libs/ApiResponseCode'
import { getUserToken } from '../currentUser/selector'

const createConst = constantCreator('post')

export const RESET_POST = createConst('RESET_POST')
export const resetPost = () => ({ type: RESET_POST })

export const GET_POST = createConst('GET_POST')
export const GET_POST_SUCCESS = createConst('GET_POST_SUCCESS')
export const GET_POST_FAILED = createConst('GET_POST_FAILED')
export const getPost = (postId) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_POST })
    try {
      const userToken = getUserToken(getState())
      const { data: response } = await fetchPost(postId, userToken)
      dispatch({ type: GET_POST_SUCCESS, response })
      if (response.code !== ApiResponseCode.GET_POST_SUCCESS) {
        const error = new Error(response.msg)
        error.code = 404
        throw error
      }
    } catch (error) {
      dispatch({ type: GET_POST_FAILED, error })
      // Throw error for the outside catch
      throw error
    }
  }
}

export const getReadMore = (options) => {
  return async (dispatch, getState) => {
    const token = options?.userToken || getState().currentUser.get('userToken')
    const post = getState().post.getIn(['post', 'data']).toJS()
    const { clas, postID } = post
    const mid = parseInt(post.userInfo.mid, 10)

    await dispatch(fetchRandomPostList({ token, mid, postID, clas }))

    const random = getState().post.getIn(['post', 'random'])
    if (random.size >= 3 && !detectPopdailyAccount(mid)) return

    await dispatch(fetchRandomPostList({ token, mid, postID, clas, score: 1020 }))
  }
}

export const POST_RANDOM = createConst('POST_RANDOM')
export const POST_RANDOM_SUCCESS = createConst('POST_RANDOM_SUCCESS')
export const POST_RANDOM_MORE_SUCCESS = createConst('POST_RANDOM_MORE_SUCCESS')
export const POST_RANDOM_FAILED = createConst('POST_RANDOM_FAILED')
export const POST_RANDOM_MORE_FAILED = createConst('POST_RANDOM_MORE_FAILED')
export const fetchRandomPostList = ({ mid, postID = '', clas = [], score = 0 }) => {
  return async (dispatch, getState) => {
    const body = { not: { postID } }
    const isFetchMoreRandomList = score !== 0 && clas.length > 0
    const successType = isFetchMoreRandomList ? POST_RANDOM_MORE_SUCCESS : POST_RANDOM_SUCCESS
    const errorType = isFetchMoreRandomList ? POST_RANDOM_MORE_FAILED : POST_RANDOM_FAILED
    // get same poster more articles
    if (score === 0) {
      body.mid = String(mid)
    } else {
      // clas
      if (clas.length > 0) body.clas = clas[0]
      // if clas include press then return
      if (clas.includes('press')) return
      // get secret post list
      if (clas.includes('secret')) {
        body.type = 'trend'
        body.clas = 'secret'
      } else if (detectPopdailyAccount(mid)) {
        body.not.type = ['pgc', 'web'] // ignore pgc and web
      }
      // get more random post list
      body.score = score
    }

    // fetch random
    dispatch({ type: POST_RANDOM })
    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken('/list/post/random', body, userToken)
      dispatch({ type: successType, response })
    } catch (error) {
      dispatch({ type: errorType, error })
    }
  }
}

export const POST_USER_FOLLOW = createConst('POST_USER_FOLLOW')
export const POST_USER_FOLLOW_SUCCESS = createConst('POST_USER_FOLLOW_SUCCESS')
export const POST_USER_FOLLOW_FAILED = createConst('POST_USER_FOLLOW_FAILED')
export const postUserFollow = ({ followID = '', follow = 1 }) => {
  return async (dispatch, getState) => {
    dispatch({ type: POST_USER_FOLLOW })
    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken(
        '/user/follow',
        { followID, follow },
        userToken,
      )
      dispatch({ type: POST_USER_FOLLOW_SUCCESS, response })
    } catch (error) {
      dispatch({ type: POST_USER_FOLLOW_FAILED, error })
    }
  }
}

export const DELETE_POST = createConst('DELETE_POST')
export const DELETE_POST_SUCCESS = createConst('DELETE_POST_SUCCESS')
export const DELETE_POST_FAILED = createConst('DELETE_POST_FAILED')
export const deletePost = ({ postID = '' }) => {
  return async (dispatch, _getState, { i18n, alert, closeWindow }) => {
    dispatch({ type: DELETE_POST })
    try {
      const userToken = getUserToken(_getState())
      const { data: response } = await v1PostWithToken('/editor/post/del', { postID }, userToken)
      dispatch({ type: DELETE_POST_SUCCESS, response })
      alert(i18n.t('刪除貼文成功'))
      closeWindow()

      return { status: 'succeeded' }
    } catch (error) {
      dispatch({ type: DELETE_POST_FAILED, error })
      alert(i18n.t('刪除貼文失敗'))

      return { status: 'failed' }
    }
  }
}

export const DELETE_REPLY = createConst('DELETE_REPLY')
export const DELETE_REPLY_SUCCESS = createConst('DELETE_REPLY_SUCCESS')
export const DELETE_REPLY_FAILED = createConst('DELETE_REPLY_FAILED')
export const deleteReply = ({ postID = '', replyID = -1 }) => {
  return async (dispatch, _getState, { toast, i18n }) => {
    dispatch({ type: DELETE_REPLY })
    try {
      const userToken = getUserToken(_getState())
      const { data: response } = await v1PostWithToken(
        '/editor/reply/del',
        { postID, replyID },
        userToken,
      )
      dispatch({ type: DELETE_REPLY_SUCCESS, response })
      toast(i18n.t('common:刪除成功'))
    } catch (error) {
      dispatch({ type: DELETE_REPLY_FAILED, error })
    }
  }
}

export const POST_REPLY = createConst('POST_REPLY')
export const POST_REPLY_SUCCESS = createConst('POST_REPLY_SUCCESS')
export const POST_REPLY_FAILED = createConst('POST_REPLY_FAILED')
export const postReply = ({ postID = '', replyID = 0, text = '', secret = 0 }) => {
  return async (dispatch, getState, { toast, i18n, pushRoute }) => {
    try {
      dispatch({ type: POST_REPLY })
      const userToken = getUserToken(getState())
      const response = await postComment({ postID, replyID, text, isSecret: secret }, userToken)
      dispatch({ type: POST_REPLY_SUCCESS, payload: response.data })
      toast(i18n.t('common:回覆送出成功'))
      return {
        status: 'succeeded',
        articleId: postID,
        replyId: response.data.list?.[0]?._id ?? null,
      }
    } catch (err) {
      dispatch({ type: POST_REPLY_FAILED, payload: { err } })
      if (err.response?.status === 403) {
        dispatch(
          openConfirmDialog({
            title: i18n.t('common:forbidden.alert.title'),
            content: i18n.t('common:forbidden.alert.message'),
            sureButtonTitle: i18n.t('common:forbidden.alert.sureButtonTitle'),
            cancelButtonTitle: i18n.t('common:forbidden.alert.cancelButtonTitle'),
            onSure: () => {
              pushRoute('/settings/report')
            },
          }),
        )
      }
      return { status: 'failed', articleId: postID, replyId: null }
    }
  }
}

export const RESET_POST_REPLY_IMAGES = createConst('RESET_POST_REPLY_IMAGES')
export const resetPostReplyImages = () => ({ type: RESET_POST_REPLY_IMAGES })

export const SET_POST_REPLY_IMAGES = createConst('SET_POST_REPLY_IMAGES')
export const setPostReplyImages = (imgList) => ({
  type: SET_POST_REPLY_IMAGES,
  payload: { imgList },
})

export const POST_IMAGE_REPLY_SUCCESS = createConst('POST_IMAGE_REPLY_SUCCESS')
export const postImageReplaySuccess = (results) => ({
  type: POST_IMAGE_REPLY_SUCCESS,
  payload: { results },
})

export const POST_IMAGE_REPLY_FAILED = createConst('POST_IMAGE_REPLY_FAILED')
export const postImageReplayFailed = (err) => ({ type: POST_IMAGE_REPLY_FAILED, payload: { err } })

export const UPLOAD_FILE_PROGRESS = createConst('UPLOAD_FILE_PROGRESS')
export const UPLOAD_FILE_SUCCESS = createConst('UPLOAD_FILE_SUCCESS')
export const UPLOAD_FILE_FAILED = createConst('UPLOAD_FILE_FAILED')

export const postImgReply = ({ postID = '', replyID = 0, index = -1, imgObj, secret = 0 }) => {
  return async (dispatch, _getState, { i18n, toast }) => {
    if (index === -1) return

    const data = {
      postID,
      b: replyID,
      text: '',
      img: { [index]: { img: imgObj?.img } },
      secret,
    }

    const onUploadProgress = (data) =>
      dispatch({ type: UPLOAD_FILE_PROGRESS, payload: { index, data } })

    try {
      const userToken = getUserToken(_getState())
      const res = await v1PostWithToken('/editor/reply', data, { onUploadProgress }, userToken)

      const response = res.data
      dispatch({ type: UPLOAD_FILE_SUCCESS, response })
      toast(i18n.t('common:回覆送出成功'))
    } catch (err) {
      dispatch({ type: UPLOAD_FILE_FAILED, payload: { err } })
      if (err.response?.status === 403) {
        alert(
          '您因違反社群守則，已被停權禁止發文。\n有任何問題請聯絡我們，謝謝！\nhttps://www.popdaily.com.tw/about/contact',
        )
      }
      throw err
    }
  }
}
export const GET_REPLY = createConst('GET_REPLY')
export const GET_REPLY_SUCCESS_DONE = createConst('GET_REPLY_SUCCESS_DONE')
export const GET_REPLY_FAILED = createConst('GET_REPLY_FAILED')
export const getReply = () => async (dispatch, getState) => {
  const postID = getState().post.getIn(['post', 'data', 'postID'])

  dispatch({ type: GET_REPLY })
  try {
    const userToken = getUserToken(getState())
    const { data: response } = await fetchReply(postID, userToken)
    dispatch({ type: GET_REPLY_SUCCESS_DONE, payload: { topComment: -1 }, response })
  } catch (error) {
    dispatch({ type: GET_REPLY_FAILED, error })
  }
}

export const invalidatePost = (postId) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_POST })
    const userToken = getUserToken(getState())
    try {
      const { data: response } = await fetchPost(postId, userToken)
      dispatch({ type: GET_POST_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GET_POST_FAILED, error })
    }

    dispatch({ type: GET_REPLY })
    try {
      const { data: response } = await fetchReply(postId, userToken)
      dispatch({ type: GET_REPLY_SUCCESS_DONE, response, payload: { topComment: -1 } })
    } catch (error) {
      dispatch({ type: GET_REPLY_FAILED, error })
    }

    dispatch({ type: GET_TOPIC_LIST })
    try {
      const { data: response } = await fetchTopicList(postId, userToken)
      dispatch({ type: GET_TOPIC_LIST_SUCCESS, response, postId })
    } catch (error) {
      dispatch({ type: GET_TOPIC_LIST_FAILED, error })
    }
  }
}
