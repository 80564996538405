import React from 'react'
import Head from 'next/head'
import appUrl, { imageUrl, ogUrl, appName, appLinks } from '@libs/appUrl'
import config from '@public-config'

import { useRouter } from 'next/router'
import { useAmp } from 'next/amp'

const JSONLD_DATA_WEBSITE = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: 'https://www.popdaily.com.tw/',
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://www.popdaily.com.tw/search/{search_term_string}',
    'query-input': 'required name=search_term_string',
  },
}

const JSONLD_DATA_ORGANIZATION = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  legalName: 'PopDaily 波波黛莉的異想世界 - 台灣',
  url: 'https://www.popdaily.com.tw',
  logo: 'https://static.popdaily.com.tw/statics/images/popdaily-logo.svg',
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+886-2-87730883',
    contactType: 'customer service',
    areaServed: 'TW',
    availableLanguage: ['Chinese'],
  },
  sameAs: [
    'https://www.facebook.com/PopDailyTW/',
    'https://www.youtube.com/channel/UCa40JCy-ESpqENGHBmml7KA',
    'https://www.instagram.com/popdaily/',
  ],
}

function DefaultHead(props) {
  const { isApp } = props
  const router = useRouter()
  const isAmp = useAmp()

  const isCanonicalPath = () => {
    const paths = [
      '/',
      '/explore/[clas]',
      '/[website]',
      '/[website]/[postID]',
      '/forum/[forum]/[postID]',
      '/map',
      '/place/[placeId]',
    ]
    return paths.includes(router.pathname)
  }

  const canonicalHref = `${appUrl.hostUrl}${router.asPath}`
  const isTagPage = router.route.includes('/tag/')

  return (
    <Head>
      {isCanonicalPath() && <link key="canonical" rel="canonical" href={canonicalHref} />}
      <link rel="dns-prefetch" href={appUrl.hostUrl} />
      <link rel="dns-prefetch" href={appUrl.imageBaseUrl} />
      <link rel="dns-prefetch" href="//fonts.googleapis.com" />
      <link rel="shortcut icon" href={imageUrl('/statics/favicons/202105favicon.png')} />
      <title key="title">PopDaily 波波黛莉的異想世界</title>
      {!isAmp && (
        <meta
          key="viewport"
          name="viewport"
          content={`initial-scale=1.0, width=device-width, maximum-scale=1.0, user-scalable=${
            isApp ? 0 : 1
          }, viewport-fit=cover`}
        />
      )}
      {/* 電商要幫 Agoda 聯盟行銷，驗證網域用的  */}
      <meta name="agd-partner-manual-verification" />
      <meta
        name="ahrefs-site-verification"
        content="f9bc7122d9782937c96d6d0beddc55335a624889c645eef2263008fad2f10275"
      />
      <meta key="charset" charSet="utf-8" />
      <meta key="theme-color" name="theme-color" content="#ffffff" />
      <meta key="application-name" name="application-name" content="PopDaily" />
      {/* gliacloud:meta:data */}
      <meta name="dailymotion-domain-verification" content="dm4evxpwmstww0lxf" />
      {/* og:meta:data */}
      <meta key="og:url" property="og:url" content={ogUrl(router.asPath)} />
      <meta key="og:site_name" property="og:site_name" content="PopDaily" />
      <meta key="og:locale" property="og:locale" content="zh_TW" />
      <meta key="fb:app_id" property="fb:app_id" content={config.fbAppId} />
      <meta key="al:ios:app_name" property="al:ios:app_name" content={appName} />
      <meta key="al:ios:app_store_id" property="al:ios:app_store_id" content="1410055224" />
      <meta key="al:ios:url" property="al:ios:url" content={appLinks(router.asPath)} />
      <meta key="al:android:app_name" property="al:android:app_name" content={appName} />
      <meta key="al:android:package" property="al:android:package" content="com.popdaily" />
      <meta key="al:web:should_fallback" property="al:web:should_fallback" content="true" />
      <meta
        key="description"
        name="description"
        content="PopDaily 波波黛莉的異想世界，女孩的吃喝玩樂、購物生活都在這：）"
      />
      <meta key="og:type" property="og:type" content="website" />
      <meta key="og:title" property="og:title" content="PopDaily 波波黛莉的異想世界" />
      <meta
        key="og:description"
        property="og:description"
        content="PopDaily 波波黛莉的異想世界，女孩的吃喝玩樂、購物生活都在這：）"
      />
      <meta key="og:image" property="og:image" content={imageUrl('/statics/images/og.jpg')} />
      <meta key="og:image:width" property="og:image:width" content="1560" />
      <meta key="og:image:height" property="og:image:height" content="820" />
      <meta key="twitter:title" property="twitter:title" content="PopDaily 波波黛莉的異想世界" />
      <meta
        key="twitter:description"
        property="twitter:description"
        content="PopDaily 波波黛莉的異想世界，女孩的吃喝玩樂、購物生活都在這：）"
      />
      <meta
        key="twitter:image"
        property="twitter:image"
        content={imageUrl('/statics/images/og.jpg')}
      />
      {/* async google web-font Noto Sans */}
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
        media="print"
        onLoad="this.media='all'"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap"
        media="print"
        onLoad="this.media='all'"
      />
      {/* ld+json */}
      {!isTagPage && (
        <script
          key="JSONLD_DATA_WEBSITE"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(JSONLD_DATA_WEBSITE) }}
        />
      )}

      <script
        key="JSONLD_DATA_ORGANIZATION"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(JSONLD_DATA_ORGANIZATION) }}
      />
    </Head>
  )
}

export default DefaultHead
