import Immutable from 'immutable'
import * as ProfileActionType from './actions'
import * as ApiResponseCode from '../libs/ApiResponseCode'
import { createSecretColor, listLikeUpdate } from '../posts/postTools'

export const initialState = Immutable.fromJS({
  userInfo: {
    data: {
      id: '',
      mid: '',
      uid: '',
      des: null,
      followers: 0,
      following: 0,
      views: 0,
      name: null,
      photoUrl: null,
      photoPath: null,
      isUserFollowing: false,
      roles: [],
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    },
    isLoading: false,
    errorMessage: '',
  },
  userPosts: {
    type: 'user',
    mode: 'new',
    clas: '',
    list: [],
    currentList: [],
    page: -1,
    score: 99999,
    hasMore: true,
    isLoading: false,
    code: 90000,
    errorMessage: '',
  },
  userCollections: {
    type: 'user',
    mode: 'new',
    clas: '',
    list: [],
    currentList: [],
    page: -1,
    score: 99999,
    hasMore: true,
    isLoading: false,
    code: 90000,
    errorMessage: '',
  },
  followMutation: {
    isLoading: false,
    errorMessage: '',
  },
  postLikeMutation: {
    isLoading: false,
  },
  isMeRoute: false,
  isGuest: false,
  isSelf: false,
})
//
const profileReducer = (state = initialState, action) => {
  const { type = '', response = {}, payload = {} } = action
  switch (type) {
    case '__NEXT_REDUX_WRAPPER_HYDRATE__':
      return Immutable.fromJS(action.payload.profile)

    case ProfileActionType.GET_USER_INFO: {
      return state.setIn(['userInfo', 'isLoading'], true).setIn(['userInfo', 'errorMessage'], '')
    }

    case ProfileActionType.GET_USER_INFO_SUCCESS: {
      return state
        .setIn(['userInfo', 'data'], Immutable.fromJS(response))
        .setIn(['userInfo', 'isLoading'], false)
        .setIn(['userInfo', 'errorMessage'], '')
    }

    case ProfileActionType.GET_USER_INFO_FAILED: {
      return state
        .setIn(['userInfo', 'errorMessage'], response?.error?.message)
        .setIn(['userInfo', 'isLoading'], false)
    }

    case ProfileActionType.SET_IS_ME_ROUTE: {
      return state.setIn(['isMeRoute'], payload.isMeRoute)
    }

    case ProfileActionType.SET_IS_GUEST: {
      return state.setIn(['isGuest'], payload.isGuest)
    }

    case ProfileActionType.SET_IS_SELF: {
      return state.setIn(['isSelf'], payload.isSelf)
    }

    case ProfileActionType.FOLLOW: {
      return state.setIn(['followMutation', 'isLoading'], true)
    }

    case ProfileActionType.FOLLOW_FAILED: {
      return state.setIn(['followMutation', 'isLoading'], false)
    }

    case ProfileActionType.FOLLOW_SUCCESS: {
      const { code = 90000 } = response
      if (code === ApiResponseCode.USER_FOLLOW_UNFOLLOW) {
        const isUserFollowing = state.getIn(['userInfo', 'data', 'isUserFollowing'])
        const followers = state.getIn(['userInfo', 'data', 'followers'])
        return state
          .setIn(['userInfo', 'data', 'isUserFollowing'], !isUserFollowing)
          .setIn(['followMutation', 'isLoading'], false)
          .setIn(['userInfo', 'data', 'followers'], isUserFollowing ? followers - 1 : followers + 1)
      }
    }

    // eslint-disable-next-line no-fallthrough
    case ProfileActionType.GET_USER_POST_LIST: {
      const { type, clas, mode, page, score } = action.payload || {}
      return state
        .setIn(['userPosts', 'type'], type || state.getIn(['userPosts', 'type']))
        .setIn(['userPosts', 'clas'], clas || state.getIn(['userPosts', 'clas']))
        .setIn(['userPosts', 'mode'], mode || state.getIn(['userPosts', 'mode']))
        .setIn(['userPosts', 'page'], page || state.getIn(['userPosts', 'page']))
        .setIn(['userPosts', 'score'], score || state.getIn(['userPosts', 'score']))
        .setIn(['userPosts', 'isLoading'], true)
        .setIn(['userPosts', 'code'], 90000)
        .setIn(['userPosts', 'errorMessage'], '')
        .updateIn(['userPosts', 'list'], (list) => {
          return action.page === 9999999999999 || action.page === 0 ? Immutable.fromJS([]) : list
        })
    }

    case ProfileActionType.GET_USER_POST_LIST_SUCCESS: {
      const { list = [], nextPage, code = 90000, msg = '' } = response
      const currentList = list.map(({ postID, t }) => ({ postID, t }))
      const mode = state.getIn(['userPosts', 'mode']) || 'new'
      const page = nextPage || list[list.length - 1]?.t || (mode === 'hot' ? 0 : 9999999999999)
      const score = Math.min(...list.map((el) => el.score))
      const hasMore = list.length >= 7

      let newState = state
        .setIn(['userPosts', 'isLoading'], false)
        .setIn(['userPosts', 'code'], code)
        .setIn(['userPosts', 'errorMessage'], msg)

      if (code === ApiResponseCode.GET_LIST_SUCCESS) {
        newState = newState
          .updateIn(['userPosts', 'list'], (_list) =>
            _list.concat(Immutable.fromJS(createSecretColor(list))),
          )
          .setIn(['userPosts', 'currentList'], Immutable.fromJS(currentList))
          .setIn(['userPosts', 'page'], page)
          .setIn(['userPosts', 'score'], score)
          .setIn(['userPosts', 'hasMore'], hasMore)
      }

      return newState
    }

    case ProfileActionType.GET_USER_POST_LIST_FAILED: {
      const { code = 90000, msg = '' } = response

      return state
        .setIn(['userPosts', 'code'], code)
        .setIn(['userPosts', 'errorMessage'], msg)
        .setIn(['userPosts', 'hasMore'], false)
        .setIn(['userPosts', 'isLoading'], false)
    }

    case ProfileActionType.SET_USER_POSTS_TYPE:
      return payload.type ? state.setIn(['userPosts', 'type'], payload.type) : state

    case ProfileActionType.SET_USER_POSTS_CLAS:
      return payload.clas ? state.setIn(['userPosts', 'clas'], payload.clas) : state

    case ProfileActionType.SET_USER_POSTS_MODE:
      return payload.mode
        ? state
            .setIn(['userPosts', 'mode'], payload.mode)
            .setIn(['userPosts', 'page'], payload.mode === 'hot' ? 0 : 9999999999999)
        : state

    case ProfileActionType.GET_USER_COLLECTION_LIST: {
      const { type, clas, mode, page, score } = action.payload || {}
      return state
        .setIn(['userCollections', 'type'], type || state.getIn(['userCollections', 'type']))
        .setIn(['userCollections', 'clas'], clas || state.getIn(['userCollections', 'clas']))
        .setIn(['userCollections', 'mode'], mode || state.getIn(['userCollections', 'mode']))
        .setIn(['userCollections', 'page'], page || state.getIn(['userCollections', 'page']))
        .setIn(['userCollections', 'score'], score || state.getIn(['userCollections', 'score']))
        .setIn(['userCollections', 'isLoading'], true)
        .setIn(['userCollections', 'code'], 90000)
        .setIn(['userCollections', 'errorMessage'], '')
        .updateIn(['userCollections', 'list'], (list) => {
          return action.page === 9999999999999 || action.page === 0 ? Immutable.fromJS([]) : list
        })
    }

    case ProfileActionType.GET_USER_COLLECTION_LIST_SUCCESS: {
      const { list = [], nextPage, code = 90000, msg = '' } = response
      const currentList = list.map(({ postID, t }) => ({ postID, t }))
      const mode = state.getIn(['userCollections', 'mode']) || 'new'
      const page = nextPage || list[list.length - 1]?.t || (mode === 'hot' ? 0 : 9999999999999)
      const score = Math.min(...list.map((el) => el.score))
      const hasMore = list.length >= 7

      let newState = state
        .setIn(['userCollections', 'isLoading'], false)
        .setIn(['userCollections', 'code'], code)
        .setIn(['userCollections', 'errorMessage'], msg)

      if (code === ApiResponseCode.GET_LIST_SUCCESS) {
        newState = newState
          .updateIn(['userCollections', 'list'], (_list) =>
            _list.concat(Immutable.fromJS(createSecretColor(list))),
          )
          .setIn(['userCollections', 'currentList'], Immutable.fromJS(currentList))
          .setIn(['userCollections', 'page'], page)
          .setIn(['userCollections', 'score'], score)
          .setIn(['userCollections', 'hasMore'], hasMore)
      }

      return newState
    }

    case ProfileActionType.GET_USER_COLLECTION_LIST_FAILED: {
      const { code = 90000, msg = '' } = response

      return state
        .setIn(['userCollections', 'code'], code)
        .setIn(['userCollections', 'errorMessage'], msg)
        .setIn(['userCollections', 'hasMore'], false)
        .setIn(['userCollections', 'isLoading'], false)
    }

    case ProfileActionType.SET_USER_COLLECTIONS_TYPE:
      return payload.type ? state.setIn(['userCollections', 'type'], payload.type) : state

    case ProfileActionType.SET_USER_COLLECTIONS_CLAS:
      return payload.clas ? state.setIn(['userCollections', 'clas'], payload.clas) : state

    case ProfileActionType.SET_USER_COLLECTIONS_MODE:
      return payload.mode
        ? state
            .setIn(['userCollections', 'mode'], payload.mode)
            .setIn(['userCollections', 'page'], payload.mode === 'hot' ? 0 : 9999999999999)
        : state

    case ProfileActionType.POST_LIKE: {
      return state.setIn(['postLikeMutation', 'isLoading'], true)
    }

    case ProfileActionType.POST_LIKE_SUCCESS_DONE: {
      const { code = 90000 } = response
      const { postID = '' } = payload
      let newState = state
      if (code === ApiResponseCode.LIKE_SUCCESS && postID) {
        newState = state.updateIn(['userPosts', 'list'], listLikeUpdate(payload))
      }
      return newState.setIn(['postLikeMutation', 'isLoading'], false)
    }

    case ProfileActionType.POST_LIKE_ERROR: {
      return state.setIn(['postLikeMutation', 'isLoading'], false)
    }

    default:
      return state
  }
}

export default profileReducer
