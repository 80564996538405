import { rest } from 'msw'
import pugcArticle from './pugcArticle.json'

export const handlers = [
  rest.post(/\/api\/post\/get/, (req, res, ctx) => {
    // console.log('req.body', req.body)
    // console.log('req.params', req.params)
    return res(ctx.status(200), ctx.json(pugcArticle))
  }),
]
