import Immutable from 'immutable'
import * as ActionType from './actions'
import * as PostActionType from '../post/actions'
import * as ApiResponseCode from '../libs/ApiResponseCode'

export const initialState = Immutable.fromJS({
  isApp: false,
  r18Alert: {
    isOpen: false,
  },
  loginAlert: {
    isOpen: false,
  },
  topicList: {
    isOpen: false,
    url: '',
  },
  report: {
    isOpen: false,
  },
  replyBar: {
    currentReplyId: 0,
    isOpen: false,
  },
  sharePop: {
    isOpen: false,
    url: '',
  },
  replyPop: {
    isOpen: false,
    replyID: -1,
    isCurrentUserComment: false,
  },
  messageDialog: {
    isOpen: false,
    title: '',
    message: '',
  },
  confirmDialog: {
    isOpen: false,
    title: '',
    content: '',
    sureButtonTitle: '',
    cancelButtonTitle: '',
    onSure: () => {},
    afterClose: () => {},
    afterSure: () => {},
  },
  //for app
  appState: '',
  isClickedMap: false,
  eventEmitter: null,
  unsubscribeDynamicLink: null,
  trackEventQueueTimer: null,
})

export default function appReducer(state = initialState, action) {
  const { type, response, payload = {} } = action
  const { replyId = 0 } = payload
  switch (type) {
    case '__NEXT_REDUX_WRAPPER_HYDRATE__':
      return Immutable.fromJS(action.payload.app)

    case PostActionType.GET_POST_SUCCESS: {
      const { code = 90000 } = response
      if (code === ApiResponseCode.GET_POST_SUCCESS) {
        const { hashTags = [] } = response
        const r18Alert = hashTags.includes('色色話題') || hashTags.includes('更多色色話題')

        return state.setIn(['r18Alert', 'isOpen'], r18Alert)
      }
      return state
    }

    case ActionType.CLOSE_R18_ALERT:
      return state.setIn(['r18Alert', 'isOpen'], false)

    case PostActionType.DELETE_REPLY_SUCCESS:
    case PostActionType.DELETE_REPLY_FAILED: {
      return state.setIn(['replyPop', 'isOpen'], false).setIn(['confirmDialog', 'isOpen'], false)
    }

    case ActionType.OPEN_LOGIN_ALERT:
      return state.setIn(['loginAlert', 'isOpen'], true)

    case ActionType.CLOSE_LOGIN_ALERT:
      return state.setIn(['loginAlert', 'isOpen'], false)

    case ActionType.OPEN_TOPIC_LIST:
      return state.setIn(['topicList', 'isOpen'], true)

    case ActionType.CLOSE_TOPIC_LIST:
      return state.setIn(['topicList', 'isOpen'], false)

    case ActionType.SET_REPLY_ID:
      return state.setIn(['replyBar', 'currentReplyId'], replyId)

    case ActionType.OPEN_REPLY_BAR:
      return state.setIn(['replyBar', 'isOpen'], true)

    case ActionType.CLOSE_REPLY_BAR:
      return state.setIn(['replyBar', 'isOpen'], false)

    case ActionType.OPEN_REPORT:
      return state.setIn(['report', 'isOpen'], true)

    case ActionType.CLOSE_REPORT:
      return state.setIn(['report', 'isOpen'], false)

    case ActionType.OPEN_SHARE_POP: {
      const { url, post, replyID = -1 } = action
      return state
        .setIn(['replyPop', 'isOpen'], false)
        .setIn(['sharePop', 'isOpen'], true)
        .setIn(['sharePop', 'url'], url)
        .setIn(['sharePop', 'post'], post)
        .setIn(['sharePop', 'replyID'], replyID)
    }

    case ActionType.CLOSE_SHARE_POP: {
      return state.setIn(['sharePop', 'isOpen'], false)
    }

    case ActionType.OPEN_REPLY_POP: {
      const { post, replyID = -1, isCurrentUserComment = false } = action
      return state
        .setIn(['sharePop', 'isOpen'], false)
        .setIn(['replyPop', 'isOpen'], true)
        .setIn(['replyPop', 'post'], post)
        .setIn(['replyPop', 'replyID'], replyID)
        .setIn(['replyPop', 'isCurrentUserComment'], isCurrentUserComment)
    }

    case ActionType.CLOSE_REPLY_POP: {
      return state.setIn(['replyPop', 'isOpen'], false)
    }

    case ActionType.OPEN_MESSAGE_DIALOG: {
      const { title, message } = action
      return state.set(
        'messageDialog',
        Immutable.fromJS({
          isOpen: true,
          title,
          message,
        }),
      )
    }

    case ActionType.CLOSE_MESSAGE_DIALOG:
      return state.setIn(['messageDialog', 'isOpen'], false)

    case ActionType.REPORT_REPLY_SUCCESS:
    case ActionType.REPORT_REPLY_FAILED: {
      return state.setIn(['replyPop', 'isOpen'], false).setIn(['confirmDialog', 'isOpen'], false)
    }

    case ActionType.SET_IS_APP: {
      return state.set('isApp', action.isApp)
    }

    case ActionType.OPEN_CONFIRM_DIALOG: {
      const {
        title = '',
        content = '',
        sureButtonTitle,
        cancelButtonTitle,
        onSure,
        afterClose,
        afterSure,
      } = action
      return state
        .setIn(['confirmDialog', 'isOpen'], true)
        .setIn(['confirmDialog', 'title'], title)
        .setIn(['confirmDialog', 'content'], content)
        .setIn(['confirmDialog', 'sureButtonTitle'], sureButtonTitle)
        .setIn(['confirmDialog', 'cancelButtonTitle'], cancelButtonTitle)
        .setIn(['confirmDialog', 'onSure'], onSure)
        .setIn(['confirmDialog', 'afterClose'], afterClose ? afterClose : () => {})
        .setIn(['confirmDialog', 'afterSure'], afterSure ? afterSure : () => {})
    }

    case ActionType.CLOSE_CONFIRM_DIALOG: {
      return state.setIn(['confirmDialog', 'isOpen'], false)
    }

    //for app
    case ActionType.SET_APP_STATE:
      return state.set('appState', action.appState)

    case ActionType.SET_EVENT_EMITTER:
      return state.set('eventEmitter', action.eventEmitter)

    case ActionType.SET_UNSUBSCRIBE_DYNAMIC_LINK:
      return state.set('unsubscribeDynamicLink', action.unsubscribeDynamicLink)

    case ActionType.SET_TRACK_EVENT_QUEUE_TIMER:
      return state.set('trackEventQueueTimer', action.trackEventQueueTimer)

    case ActionType.SET_IS_CLICKED_MAP:
      return state.set('isClickedMap', action.isClickedMap)

    default:
      return state
  }
}
