import PageNavigationItf from '@app-core/common/PageNavigationItf'

class PageNavigation extends PageNavigationItf {
  reload(forceGet) {
    window.location.reload(forceGet)
  }
}

const pageNavigation = new PageNavigation()
export default pageNavigation
