import React from 'react'
import NextApp from 'next/app'
import { DefaultHead } from '@components/Head/index'
import GoogleTagManagerScript from '@components/GoogleTagManagerScript'
import { ApolloProvider } from '@apollo/client'
import { getApolloClient } from '@libs/getApolloClient'
import { compose, appWithTranslation, connect } from '@hocs'
import { i18n } from '@i18n'
import 'moment/locale/zh-tw'
import { TailwindThemeProvider, MuiThemeProvider } from '@components/Theme'
import OneSignal from 'react-onesignal'
// polyfill Intersection Observer API
import 'intersection-observer'
import { UnknownError } from '@features/errors/UnknownError'
import { withReduxStore } from '@configureStore'
import config from '@public-config'
import { withNotification } from '@features/notification/withNotification'
import appWithCheckUser from '@libs/appWithCheckUser'
import appWithEventTracker from '@libs/appWithEventTracker'
import { applyErrorHandleInterceptor } from '@app-core/api/v2'
import { DeviceContext } from '@context'
import appWithRNApp from '@libs/appWithRNApp'
import { setBaseUrl } from '@app-core/api'
import CSSResetStyle from '@components/CSSResetStyle'

if (config.mockServerEnabled === 'true') {
  if (typeof window === 'undefined') {
    const { server } = require('../src/mocks/msw-node')
    server.listen()
  } else {
    const { worker } = require('../src/mocks/msw-browser')
    worker.start()
  }
}

!(() => {
  let baseUrl = '/api'

  if (config.coreApiEndpoint) {
    // `coreApiEndpoint` is from web only
    baseUrl = config.coreApiEndpoint
  } else if (config.url) {
    // `url` is from app only
    baseUrl = `${config.url}/api`
  }

  setBaseUrl(baseUrl)
})()

class App extends NextApp {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {}
    if (Component.getInitialProps) {
      pageProps =
        (await Component.getInitialProps({
          ...ctx,
          namespacesRequired: i18n.namespacesRequired,
        })) || {}
    }
    return {
      pageProps,
      namespacesRequired: i18n.namespacesRequired,
    }
  }

  state = {
    hasError: false,
  }

  componentDidMount() {
    applyErrorHandleInterceptor()

    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
    if (config.oneSignalEnabled) {
      OneSignal.initialize(config.oneSignalAppId, {
        autoResubscribe: true,
        safari_web_id: config.oneSignalSafariWebId,
      })
    }
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  client = getApolloClient()

  render() {
    const { hasError } = this.state
    const { isApp = false, Component, pageProps } = this.props

    if (hasError) {
      return <UnknownError />
    }

    return (
      <>
        <DefaultHead isApp={isApp} />
        <DeviceContext.Provider value={{ isApp }}>
          <MuiThemeProvider>
            <ApolloProvider client={this.client}>
              <TailwindThemeProvider>
                <Component {...pageProps} isApp={isApp} />
              </TailwindThemeProvider>
            </ApolloProvider>
          </MuiThemeProvider>
        </DeviceContext.Provider>
        <GoogleTagManagerScript isApp={isApp} />
        <CSSResetStyle />
      </>
    )
  }
}

export default compose(
  withReduxStore,
  connect(null),
  appWithTranslation,
  appWithCheckUser,
  appWithEventTracker,
  appWithRNApp,
  withNotification,
)(App)
