import { useEffect } from 'react'
import { setIsApp } from '@app-core/app/actions'

import RNApp from './RNApp'
import { setLS } from './LS'

const appWithRNApp = (App) => {
  const EnhancedApp = (props) => {
    const { isApp } = props
    useEffect(() => {
      if (!isApp) return
      window.RNApp = RNApp

      const onMessage = (e) => {
        try {
          if (typeof e.data != 'string' || e.data.indexOf('command') < 0) {
            return
          }
          const message = JSON.parse(e.data)
          if (!message) return null

          if (message.command === 'setVersion') {
            setLS('appVersion', message.version)
          } else if (message.command === 'setLocation') {
            if (!message.location) return

            if (
              (message.location.lat && message.location.lng) ||
              message.location.errorMessageForGetUserLocation
            ) {
              // TODO: window 這三個變數之後可以移除，為了支援舊版本 (2020/03/24)
              Object.assign(
                {
                  lat: message.location.lat,
                  lng: message.location.lng,
                  errorMessageForGetUserLocation: message.payload.errorMessageForGetUserLocation,
                },
                window,
              )

              setLS('appLocation', message.location)
            }
          } else if (message.command === 'setDeviceModel') {
            setLS('appDeviceModel', message.device)
          } else if (message.command === 'RNAppButtonTabTap') {
            if (window.scrollY == 0) return RNApp.startLoading()
            window.scrollTo({ behavior: 'smooth', top: 200 })
          } else if (message.command === 'ping') {
            RNApp.postMessage('pong')
          } else if (message.command === 'getMyLocation') {
            // 舊版 RNAPP 使用，之後可以移除 (2020/04/03)

            Object.assign(
              {
                lat: message.payload.lat,
                lng: message.payload.lng,
                errorMessageForGetUserLocation: message.payload.errorMessageForGetUserLocation,
              },
              window,
            )
          }
        } catch (e) {
          // TODO: 這裡不應該有錯誤，如果有錯誤應修正 RN
          console.error(e)
        }
      }

      // FIXME: APP 已經不會顯示 loading 畫面了
      // 可以考慮移除這行
      RNApp.stopLoading()

      window.addEventListener('message', onMessage)

      return () => {
        window.removeEventListener('message', onMessage)
      }
    }, [])
    return <App {...props} />
  }

  function checkIsApp(ctx) {
    const { query, store } = ctx

    const fromQuery = query?.is_app === 'true'
    const fromStore = store.getState().app.get('isApp')

    const isApp = fromQuery || fromStore

    store.dispatch(setIsApp({ isApp }))

    return isApp
  }

  EnhancedApp.getInitialProps = async ({ Component, ctx }) => {
    const isApp = checkIsApp(ctx)
    const props = App.getInitialProps && (await App.getInitialProps({ Component, ctx }))

    return { ...props, isApp }
  }
  return EnhancedApp
}

export default appWithRNApp
