import Immutable from 'immutable'
import * as ActionType from './actions'

export const initialState = Immutable.fromJS({
  events: {
    page: 9999999999999,
    popCoffeeDaily: [],
    hasMore: true,
    isLoading: false,
    code: 90000,
    errorMessage: '',
  },
})

export default function eventsReducer(state = initialState, action) {
  const { type = '', response = {} } = action

  switch (type) {
    case '__NEXT_REDUX_WRAPPER_HYDRATE__':
      return Immutable.fromJS(action.payload.events)

    case ActionType.GET_EVENT_IG_LIST: {
      return state
        .setIn(['events', 'isLoading'], true)
        .setIn(['events', 'code'], 90000)
        .setIn(['events', 'errorMessage'], '')
    }

    case ActionType.GET_EVENT_IG_LIST_SUCCESS: {
      const { list = [], code = 90000, msg = '' } = response
      const page = list[list.length - 1].t || 9999999999999
      // const hasMore = list.length >= 7
      const hasMore = false

      let newState = state
        .setIn(['events', 'page'], page)
        .updateIn(['events', 'popCoffeeDaily'], (_list) => _list.concat(Immutable.fromJS(list)))
        .setIn(['events', 'isLoading'], false)
        .setIn(['events', 'code'], code)
        .setIn(['events', 'errorMessage'], msg)
        .setIn(['events', 'hasMore'], hasMore)

      return newState
    }

    case ActionType.GET_EVENT_IG_LIST_FAILED: {
      const { code = 90000, msg = '' } = response

      return state
        .setIn(['events', 'isLoading'], false)
        .setIn(['events', 'code'], code)
        .setIn(['events', 'errorMessage'], msg)
        .setIn(['events', 'hasMore'], false)
    }

    default:
      return state
  }
}
