import Immutable from 'immutable'
import * as ActionType from './actions'

export const initialState = Immutable.fromJS({
  notifyState: {
    isLoading: true,
    newMsg: 0,
    lastSeen: 0,
  },
  userNotifyList: {
    data: [],
    isLoading: true,
    errorMessage: '',
    done: false,
  },
  systemNotifyList: {
    data: [],
    isLoading: true,
    errorMessage: '',
  },
})

const toggleFollowState = function (state, followID) {
  return state.updateIn(['userNotifyList', 'data'], (list) => {
    return list.map((item) => {
      return item.getIn(['userInfo', 'mid']) === followID
        ? item.set('isFollowed', item.get('isFollowed') ^ 1)
        : item
    })
  })
}

export default function notifyReducer(state = initialState, action) {
  switch (action.type) {
    case '__NEXT_REDUX_WRAPPER_HYDRATE__':
      return Immutable.fromJS(action.payload.notify)

    case ActionType.GET_NOTIFY_STATE:
      return state.setIn(['notifyState', 'isLoading'], true)

    case ActionType.GET_NOTIFY_STATE_SUCCESS:
      return state
        .setIn(['notifyState', 'newMsg'], action.response.newMsg)
        .setIn(['notifyState', 'lastSeen'], action.response.lastSeen)
        .setIn(['notifyState', 'isLoading'], false)

    case ActionType.GET_NOTIFY_STATE_FAILED:
      return state.setIn(['notifyState', 'isLoading'], false)

    case ActionType.CLEAR_NOTIFY_STATE:
      return state.setIn(['notifyState', 'isLoading'], true)

    case ActionType.CLEAR_NOTIFY_STATE_SUCCESS:
      return state
        .setIn(['notifyState', 'newMsg'], 0)
        .setIn(['notifyState', 'lastSeen'], new Date().getTime())
        .setIn(['notifyState', 'isLoading'], false)

    case ActionType.CLEAR_NOTIFY_STATE_FAILED:
      return state.setIn(['notifyState', 'isLoading'], false)

    case ActionType.GET_USER_NOTIFY_LIST:
      return state.setIn(['userNotifyList', 'isLoading'], true)

    case ActionType.GET_USER_NOTIFY_LIST_SUCCESS:
      return state
        .setIn(['userNotifyList', 'done'], !action.response.list.length)
        .setIn(['userNotifyList', 'isLoading'], false)
        .updateIn(['userNotifyList', 'data'], (list) => {
          return list.concat(Immutable.fromJS(action.response.list)).toOrderedSet().toList()
        })
    // .setIn(
    //     ['userNotifyList', 'data'],
    //     Immutable.fromJS(action.response.list)
    // )

    case ActionType.GET_USER_NOTIFY_LIST_FAILED:
      return state.setIn(['userNotifyList', 'isLoading'], false)

    case ActionType.GET_SYSTEM_NOTIFY_LIST:
      return state.setIn(['systemNotifyList', 'isLoading'], true)

    case ActionType.GET_SYSTEM_NOTIFY_LIST_SUCCESS:
      return state
        .setIn(['systemNotifyList', 'isLoading'], false)
        .updateIn(['systemNotifyList', 'data'], (list) => {
          let newList = []
          let nowTime = new Date().getTime()
          action.response.forEach(function (e) {
            if (
              (e.startTime &&
                e.endTime &&
                nowTime >= Number(e.startTime) &&
                nowTime <= Number(e.endTime)) ||
              (e.startTime === undefined && e.endTime === undefined)
            ) {
              newList.push({
                id: e._id,
                type: 'official',
                text: e.text.replace(/\n/g, '<br />'),
                t: e.t,
                postID: e.url,
                userInfo: { mid: e.mid, img: e.img },
              })
            }
          })
          return list.concat(Immutable.fromJS(newList))
        })

    case ActionType.GET_SYSTEM_NOTIFY_LIST_FAILED:
      return state.setIn(['systemNotifyList', 'isLoading'], false)

    case ActionType.UPDATE_FOLLOW_STATE:
      return toggleFollowState(state, action.followID)

    case ActionType.UPDATE_FOLLOW_STATE_SUCCESS:
      if (action.response.code === 15006) {
        return state
      } else {
        return toggleFollowState(state, action.followID)
      }

    case ActionType.UPDATE_FOLLOW_STATE_FAILED:
      return toggleFollowState(state, action.followID)

    case ActionType.READ_NOTIFY: {
      const notifyId = state
        .getIn(['userNotifyList', 'data'])
        .findIndex((e) => e.get('id') === action.id)
      return state.updateIn(['userNotifyList', 'data', notifyId], (item) => item.set('check', 0))
    }

    default:
      return state
  }
}
