import Immutable from 'immutable'
import * as ActionType from './actions'
import * as AppActionType from '../app/actions'
import * as PostsActionType from '../posts/actions'
import * as ApiResponseCode from '../libs/ApiResponseCode'
import {
  adMasonry,
  webReplace,
  createSecretColor,
  listLikeUpdate,
  commentsLikeUpdate,
  convertComments,
} from '../posts/postTools'

export const initialState = Immutable.fromJS({
  post: {
    data: {},
    random: [],
    randomMore: [],
    comments: [],
    isLoading: false,
    uploadImages: [],
    isUploading: false,
    isConvertComments: false,
    code: 90000,
    errorMessage: '',
  },
})

export default function postReducer(state = initialState, action) {
  const { type = '', response = {}, payload = {} } = action

  switch (type) {
    case '__NEXT_REDUX_WRAPPER_HYDRATE__':
      return Immutable.fromJS(action.payload.post)

    case PostsActionType.POST_LIKE_SUCCESS_DONE: {
      const { code = 90000 } = response
      const { postID = '', like = 0 } = payload
      let newState = state
      if (code === ApiResponseCode.LIKE_SUCCESS && postID) {
        newState = state
          .setIn(
            ['post', 'data', 'like'],
            state.getIn(['post', 'data', 'like'], 0) + (like ? 1 : -1),
          )
          .setIn(['post', 'data', 'isLike'], like ? 1 : 0)
          .updateIn(['post', 'random'], listLikeUpdate(payload))
          .updateIn(['post', 'randomMore'], listLikeUpdate(payload))
      }
      return newState
    }

    case PostsActionType.POST_REPLY_LIKE_SUCCESS_DONE: {
      const { code = 90000 } = response
      const { postID = '', replyID = 0 } = payload
      let newState = state
      if (code === ApiResponseCode.LIKE_SUCCESS && postID && replyID > 0) {
        newState = state.updateIn(['post', 'comments'], commentsLikeUpdate(payload))
      }
      return newState
    }

    case ActionType.RESET_POST_REPLY_IMAGES: {
      return state.setIn(['post', 'isUploading'], false).setIn(['post', 'uploadImages'], [])
    }

    case ActionType.SET_POST_REPLY_IMAGES: {
      const { imgList = [] } = payload
      return state.setIn(['post', 'isUploading'], true).setIn(['post', 'uploadImages'], imgList)
    }

    case ActionType.UPLOAD_FILE_PROGRESS: {
      const {
        index = -1,
        data: { direction = '', percent = 0, total = 0, loaded = 0 },
      } = payload
      if (index >= 0 && direction === 'upload') {
        const progress = Math.max(parseInt((loaded / total) * 100, 10), percent)
        return state.updateIn(['post', 'uploadImages'], (imgList) => {
          return imgList.map((img) => {
            if (img.index === index) {
              img.progress = progress.toFixed(0)
            }
            return img
          })
        })
      }

      return state
    }

    case ActionType.UPLOAD_FILE_SUCCESS:
    case ActionType.UPLOAD_FILE_FAILED: {
      const { error = 0, message = '' } = response
      const { err = '' } = payload
      // api post error
      if (err) console.error(err)
      //server response error
      if (error && message) console.error(message)
      return state
    }

    case ActionType.POST_IMAGE_REPLY_SUCCESS:
    case ActionType.POST_IMAGE_REPLY_FAILED: {
      return state.setIn(['post', 'isUploading'], false)
    }

    case ActionType.GET_POST:
    case ActionType.POST_RANDOM: {
      return state
        .setIn(['post', 'isLoading'], true)
        .setIn(['post', 'code'], 90000)
        .setIn(['post', 'errorMessage'], '')
    }

    case ActionType.GET_REPLY: {
      return state
        .setIn(['post', 'isLoading'], true)
        .setIn(['post', 'isConvertComments'], true)
        .setIn(['post', 'code'], 90000)
        .setIn(['post', 'errorMessage'], '')
    }

    case ActionType.GET_REPLY_SUCCESS_DONE: {
      const { list = [], like = [], code = 90000, msg = '' } = response
      if (code === ApiResponseCode.GET_REPLY_SUCCESS) {
        const { topComment } = payload
        const comments = Immutable.fromJS(convertComments(list, like, topComment))

        return state
          .setIn(['post', 'comments'], comments)
          .setIn(['post', 'isLoading'], false)
          .setIn(['post', 'isConvertComments'], false)
          .setIn(['post', 'code'], code)
          .setIn(['post', 'errorMessage'], msg)
      } else {
        return state
          .setIn(['post', 'isLoading'], false)
          .setIn(['post', 'isConvertComments'], false)
          .setIn(['post', 'code'], code)
          .setIn(['post', 'errorMessage'], msg)
      }
    }

    case ActionType.GET_REPLY_FAILED: {
      const { code = 90000, msg = '' } = response
      return state
        .setIn(['post', 'comments'], Immutable.fromJS([]))
        .setIn(['post', 'isLoading'], false)
        .setIn(['post', 'isConvertComments'], false)
        .setIn(['post', 'code'], code)
        .setIn(['post', 'errorMessage'], msg)
    }

    case ActionType.POST_REPLY_SUCCESS: {
      const { code = 90000 } = payload
      if (code === ApiResponseCode.SUBMIT_COMMENT_SUCCESS) {
        const postReply = state.getIn(['post', 'data', 'reply'], 0)
        return state.setIn(['post', 'data', 'reply'], postReply + 1)
      }
      return state
    }

    case ActionType.DELETE_REPLY_SUCCESS: {
      const { code = 90000 } = response
      if (code === ApiResponseCode.DELETE_COMMENT_SUCCESS) {
        const postReply = state.getIn(['post', 'data', 'reply'], 0)
        return state.setIn(['post', 'data', 'reply'], postReply - 1)
      }
      return state
    }

    case ActionType.POST_RANDOM_SUCCESS: {
      const { code = 90000, msg = '' } = response
      // handle post need random secret color and handle web post
      let random = []
      if (Array.isArray(response)) {
        response.length > 0 && response.splice(1, 0, adMasonry) // add AD Block
        random = Array.isArray(response)
          ? createSecretColor(response).map((article) => webReplace(article, -1))
          : []
      }
      return state
        .setIn(['post', 'random'], Immutable.fromJS(random))
        .setIn(['post', 'isLoading'], false)
        .setIn(['post', 'code'], code)
        .setIn(['post', 'errorMessage'], msg)
    }

    case ActionType.POST_RANDOM_MORE_SUCCESS: {
      const { code = 90000, msg = '' } = response
      // handle post need more random secret color and handle web post
      let randomMore = []
      if (Array.isArray(response)) {
        response.length > 0 && response.splice(1, 0, adMasonry) // add AD Block
        randomMore = Array.isArray(response)
          ? createSecretColor(response).map((article) => webReplace(article, -1))
          : []
      }
      return state
        .setIn(['post', 'randomMore'], Immutable.fromJS(randomMore))
        .setIn(['post', 'isLoading'], false)
        .setIn(['post', 'code'], code)
        .setIn(['post', 'errorMessage'], msg)
    }

    case ActionType.POST_RANDOM_FAILED: {
      const { code = 90000, msg = '' } = response
      return state
        .setIn(['post', 'random'], Immutable.fromJS([]))
        .setIn(['post', 'isLoading'], false)
        .setIn(['post', 'code'], code)
        .setIn(['post', 'errorMessage'], msg)
    }

    case ActionType.POST_RANDOM_MORE_FAILED: {
      const { code = 90000, msg = '' } = response
      return state
        .setIn(['post', 'randomMore'], Immutable.fromJS([]))
        .setIn(['post', 'isLoading'], false)
        .setIn(['post', 'code'], code)
        .setIn(['post', 'errorMessage'], msg)
    }

    case ActionType.POST_USER_FOLLOW_SUCCESS: {
      const { code = 90000, msg = '' } = response
      if (code === ApiResponseCode.USER_FOLLOW_UNFOLLOW) {
        const isFollowPostUser = state.getIn(['post', 'data', 'isFollowPostUser'])
        return state.setIn(['post', 'data', 'isFollowPostUser'], isFollowPostUser ? 0 : 1)
      } else {
        return state.setIn(['post', 'code'], code).setIn(['post', 'errorMessage'], msg)
      }
    }

    case ActionType.GET_POST_SUCCESS: {
      const { code = 90000, msg = '' } = response
      if (code === ApiResponseCode.GET_POST_SUCCESS) {
        return state
          .setIn(['post', 'data'], Immutable.fromJS(createSecretColor(response)))
          .setIn(['post', 'isLoading'], false)
          .setIn(['post', 'code'], code)
          .setIn(['post', 'errorMessage'], msg)
      } else {
        return state
          .setIn(['post', 'isLoading'], false)
          .setIn(['post', 'code'], code)
          .setIn(['post', 'errorMessage'], msg)
      }
    }

    case ActionType.GET_POST_FAILED: {
      const { code = 90000, msg = '' } = response
      return state
        .setIn(['post', 'isLoading'], false)
        .setIn(['post', 'code'], code)
        .setIn(['post', 'errorMessage'], msg)
    }

    case ActionType.RESET_POST: {
      const { code = 90000, msg = '' } = response
      return state
        .setIn(['post', 'data'], Immutable.fromJS({}))
        .setIn(['post', 'random'], Immutable.fromJS([]))
        .setIn(['post', 'randomMore'], Immutable.fromJS([]))
        .setIn(['post', 'comments'], Immutable.fromJS([]))
        .setIn(['post', 'isLoading'], false)
        .setIn(['post', 'code'], code)
        .setIn(['post', 'errorMessage'], msg)
    }

    case AppActionType.RECALL_REPLY_SUCCESS: {
      const { code = 90000 } = response
      if (code === ApiResponseCode.SUCCESS_REPLY_DELETE) {
        const postReply = state.getIn(['post', 'data', 'reply'], 0)
        return state.setIn(['post', 'data', 'reply'], postReply - 1).setIn(
          ['post', 'comments'],
          state
            .getIn(['post', 'comments'])
            .filter((comment) => comment.get('replyID') != response.replyID),
        )
      }
      return state
    }

    default:
      return state
  }
}
