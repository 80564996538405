import constantCreator from '../libs/constantCreator'
import { v1PostWithToken } from '../api/v1'
import { getUserToken } from '../currentUser/selector'

const createConst = constantCreator('popbox')

export const GET_GIFT = createConst('GET_GIFT')
export const GET_GIFT_SUCCESS = createConst('GET_GIFT_SUCCESS')
export const GET_GIFT_FAILED = createConst('GET_GIFT_FAILED')
export function getGift({ giftID }) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_GIFT })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken(
        '/gift/get',
        { token: userToken, giftID },
        userToken,
      )
      dispatch({ type: GET_GIFT_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GET_GIFT_FAILED, error })
    }
  }
}

export const SET_GIFT_NOTIFY = createConst('SET_GIFT_NOTIFY')
export const SET_GIFT_NOTIFY_SUCCESS = createConst('SET_GIFT_NOTIFY_SUCCESS')
export const SET_GIFT_NOTIFY_FAILED = createConst('SET_GIFT_NOTIFY_FAILED')
export function setGiftNotify({ giftID }) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_GIFT_NOTIFY })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken(
        '/gift/notify',
        { token: userToken, giftID },
        userToken,
      )
      dispatch({ type: SET_GIFT_NOTIFY_SUCCESS, response })
    } catch (error) {
      dispatch({ type: SET_GIFT_NOTIFY_FAILED, error })
    }
  }
}
